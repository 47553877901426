import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Table, Row, Col, Card, Alert, Button, Radio, Space, Modal, Form, Select, List, Tag } from 'antd';
import PageLayout from '../../../../components/layout/PageLayout';
import { UpdateOrder }  from '../../../../services/orders';
import { GetOrderItemsActive } from '../../../../services/orderitems';
import { GetOrderBranches } from '../../../../services/orderbranches';
import { GetVariationDetails } from '../../../../services/itemsvariations';
import { GetAddOnDetails } from '../../../../services/addons';
import { GetUserDetails } from '../../../../services/users';
import { GetOrderTrackings } from '../../../../services/trackings';
import { GetBranchDetails } from '../../../../services/branches';
import { GetAddressDetails } from '../../../../services/addresses';
import { GetVoucherDetails } from '../../../../services/vouchers';
import { GetDeliveryPartners } from '../../../../services/deliverypartners';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { ConvertToDecimal } from '../../../../functions/Math';
import { DisplayDateTime } from '../../../../functions/Utilities';
import { Debounce } from '../../../../functions/Utilities';
import { NotificationManager } from 'react-notifications';
import ListIcon from '../../../../components/icons/List';

export default function OrdersLatestView() {
    const history = useHistory();
    const { Option } = Select;
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const orderDetails = history.location.state.orderDetails;
    const [orderBranches, setOrderBranches] = useState(null);
    const [orderItems, setOrderItems] = useState(null);
    const [orderTracking, setOrderTracking] = useState(null);
    const [deliveryPartner, setDeliveryPartner] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);
    const [addressDetails, setAddressDetails] = useState(null);
    const [voucherDetails, setVoucherDetails] = useState(null);
    const [cancelReason, setCancelReason] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [activeRiders, setActiveRiders] = useState(null);
    const [reassignTo, setReassignTo] = useState(null);
    
    const getOrderDetails = async() => {
        showLoader();
        Promise.all([
            GetUserDetails(orderDetails.delivery_partner_uuid),
            GetOrderTrackings(orderDetails.order_uuid),
            GetUserDetails(orderDetails.customer_uuid),
            GetAddressDetails(orderDetails.address_uuid),
            GetDeliveryPartners(orderDetails.service_area_uuid),
            GetOrderBranches(orderDetails.order_uuid),
            GetOrderItemsActive(orderDetails.order_uuid)
        ]).then(result => {
            setDeliveryPartner(result[0].data.data);
            let trackingResultKey = result[1].data.data.length - 1;
            setOrderTracking(result[1].data.data[trackingResultKey].tracking_code);
            if (result[2].data.status === 'success') {
                setCustomerDetails(result[2].data.data);
            } else {
                setCustomerDetails(null);
            }
            setAddressDetails(result[3].data.data);
            setActiveRiders(result[4].data.data);

            result[5].data.data.map((item, index) => {
                result[5].data.data[index].order_items = result[6].data.data.filter((a, b) => {
                    return a.order_branch_uuid === item.order_branch_uuid
                });
            });

            result[5].data.data.map((item, index) => {
                item.order_items.map((orderItem, orderIndex) => {
                    const requiredParsed = JSON.parse(orderItem.add_on_required);
                    const optionalParsed = JSON.parse(orderItem.add_on_optional);
                    result[5].data.data[index].order_items[orderIndex].add_on_required_parsed = requiredParsed;
                    result[5].data.data[index].order_items[orderIndex].add_on_optional_parsed = optionalParsed;
                    result[5].data.data[index].order_items[orderIndex].add_on_required_labels = requiredParsed.map((item) => '(' + orderItem.quantity + 'x) ' + item.add_on_name);
                    result[5].data.data[index].order_items[orderIndex].add_on_optional_labels = optionalParsed.map((item) => '(' + orderItem.quantity + 'x) ' + item.add_on_name);
                });
            });

            setOrderBranches(result[5].data.data);
            setOrderItems(result[6].data.data);
            setDisplay(true);
            hideLoader();
        }).catch(e => {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        });

        if (orderDetails.voucher_uuid !== null) {
            const voucherResult = await GetVoucherDetails(orderDetails.voucher_uuid);
            setVoucherDetails(voucherResult.data.data);
        }
    }

    const BranchItem = ({item, index}) => {
        var payMerchant = item.order_items.reduce((a, b) => {
            return a + b.total_price
        }, 0);
        return (
            <div>
                <div className="d-flex mb-2">
                    <Tag className="branch-counter">{index + 1}</Tag>
                    <div>
                        <h2 className="mb-0 lh-1">{item.branch_name}</h2>
                        <h4>Merchant Due P{ConvertToDecimal(payMerchant)}</h4>
                    </div>
                </div>
                <Table
                    rowKey={(row)=> row.item_uuid}
                    dataSource={item.order_items}
                    columns={columns}
                    size={'middle'}
                    className="bm-table mb-5"
                    pagination={false} />
            </div>
        )
    }

    const goCancelOrder = () => {
        if (cancelReason === null) {
            Debounce(NotificationManager.warning('You need to select a reason for cancelling an order.', 'Sorry', 2000), 2000);
        } else {
            setIsModalVisible(true);
        }
    }

    const goReassignOrder = () => {
        if (reassignTo === null || reassignTo === '') {
            Debounce(NotificationManager.warning('You need to select an availble Delivery Partner to reassign this order.', 'Sorry', 2000), 2000);
            return false;
        } else {
            handleReassignOrder();
        }
    }

    const onReasonChange = (value) => {
        setCancelReason(value.target.value);
    }

    const handleCancelOrder = async() => {
        showLoader(); 
        const values = {
            'order_status': 3,
            'cancel_reason': cancelReason
        }
        const cancelResult = await UpdateOrder(values, orderDetails.order_uuid);
        if (cancelResult.data.status === 'success') {
            hideLoader();
            setIsModalVisible(false);
            Debounce(NotificationManager.success('Order is now cancelled.', 'Success', 2000), 2000);
            setTimeout(() => {
                history.push('/app/admin/orders/latest');
            }, 3000)
        } else {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            hideLoader();
        }
    }
    
    const handleReassignOrder = async() => {
        showLoader();
        if (orderDetails.delivery_partner_uuid === reassignTo) {
            Debounce(NotificationManager.warning('Please select a different delivery partner.', 'Sorry', 2000), 2000);
            hideLoader();
            return false;
        }
        const values = {
            delivery_partner_uuid: reassignTo
        }
        const reassignResult = await UpdateOrder(values, orderDetails.order_uuid);
        if (reassignResult.data.status === 'success') {
            hideLoader();
            Debounce(NotificationManager.success('Order is now reassigned.', 'Success', 2000), 2000);
            setTimeout(() => {
                history.push('/app/admin/orders/latest');
            }, 3000)
        } else {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            hideLoader();
        }
    }

    const handleClose = () => {
        setIsModalVisible(false);
    }

    const columns = [
        {
            title: 'Quantity and Item',
            width: '35%',
            render: (text, row) => <span>({row.quantity}x) {row.item_name}</span>
        },
        {
            title: 'Variation',
            render: (text, row) => <span>({row.quantity}x) {row.variation_name}</span>
        },
        {
            title: 'Required Add-Ons',
            render: (text, row) => <span>{row.add_on_required_labels.join(', ')}</span>
        },
        {
            title: 'Optional Add-Ons',
            render: (text, row) => <span>{row.add_on_optional_labels.join(', ')}</span>
        },
        {
            title: 'Price',
            render: (text, row) => 'P' + ConvertToDecimal(row.total_price)
        }
    ];

    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    useEffect(() => {
        getOrderDetails();
        return function cleanup() {
            getOrderDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='orders'>
            { loader }
            {
                display && 
                <div>
                    <Row gutter={24}>
                        <Col xs={24} md={16}>
                            <h3 className="mb-4">Order Branches and Items</h3>
                            {
                                orderBranches &&
                                <List
                                    dataSource={orderBranches}
                                    renderItem={(item, index) => <BranchItem item={item} index={index} />}
                                />
                            }
                            <Row gutter={24}>
                                <Col xs={24} md={12}>
                                    <Card title="Pricing Details" className="bm-card mb-4">
                                        <div className="bm-static-container">
                                            <div className="title">Merchant Due</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.order_total_amount - orderDetails.order_admin_due_amount - orderDetails.order_delivery_due_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Delivery Fee</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.order_delivery_due_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Platform</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.order_admin_due_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Order Amount</div>
                                            <div className="display"><strong>P{ConvertToDecimal(orderDetails.order_total_amount)}</strong></div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Card title="Delivery Details" className="bm-card mb-4">
                                        <div className="bm-static-container">
                                            <div className="title">Distance</div>
                                            <div className="display">{ConvertToDecimal(orderDetails.charge_distance_meters / 10) + 'km'}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Due to Delivery Rider</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.order_delivery_due_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Delivery Commission</div>
                                            <div className="display">P{ConvertToDecimal(orderDetails.charge_delivery_commission_amount)}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Delivery Fee</div>
                                            <div className="display"><strong>P{ConvertToDecimal(orderDetails.charge_delivery_total_amount)}</strong></div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col xs={24} md={12}>
                                    {
                                        voucherDetails === null &&
                                        <Alert
                                            className="bm-alert mt-1 mb-4"
                                            message={'No Vouchers Used'}
                                            description={'Customer did not use any active vouchers on this order.'}
                                            type={'warning'}
                                        />
                                    }
                                    {
                                        (voucherDetails !== null) &&
                                        <Card title="Voucher Details" className="bm-card mb-4">
                                            <div className="bm-static-container">
                                                <div className="title">Voucher Code</div>
                                                <div className="display"><Link className="text-link-primary-x3" to={`/app/admin/vouchers/view/${voucherDetails.voucher_uuid}`}>{voucherDetails.voucher_code}</Link></div>
                                            </div>
                                            <div className="bm-static-container">
                                                <div className="title">Voucher Name</div>
                                                <div className="display">{voucherDetails.voucher_name}</div>
                                            </div>
                                            <div className="bm-static-container">
                                                <div className="title">Discount Type</div>
                                                <div className="display">{voucherDetails.discount_type}</div>
                                            </div>
                                        </Card>
                                    }
                                </Col>
                                <Col xs={24} md={12}>
                                    {
                                        (orderDetails.charge_is_free_delivery === 1) &&
                                        <Card title="Free Delivery" className="bm-card mb-4">
                                            <div className="bm-static-container">
                                                <div className="title">Base Fee</div>
                                                <div className="display">P{ConvertToDecimal(orderDetails.charge_delivery_base_amount)}</div>
                                            </div>
                                        </Card>
                                    }
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} md={8}>
                            <Card title="Order Details" className="bm-card mb-4">
                                {
                                    orderTracking && orderDetails.order_status !== 3 &&
                                    <Alert
                                        className="bm-alert mt-1 mb-4"
                                        message={'Status'}
                                        description={GetStatusLabels('tracking-admin', orderTracking)}
                                        type={(orderTracking === 'G004') ? 'success' : 'warning'}
                                    />
                                }
                                <div className="bm-static-container">
                                    <div className="title">Order Tracking No</div>
                                    <div className="display"><h3>{orderDetails.order_tracking_no}</h3></div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Delivery Partner</div>
                                    <div className="display"><h3><Link className="text-link-primary-x3" to={`/app/admin/delivery-partners/view/${orderDetails.delivery_partner_uuid}`}>{deliveryPartner.first_name} {deliveryPartner.last_name}</Link></h3></div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">{orderBranches.length > 1 ? 'Merchants': 'Merchant'}</div>
                                    {
                                        orderBranches &&
                                        <List
                                            itemLayout={'vertical'}
                                            dataSource={orderBranches}
                                            renderItem={(item) => <List.Item><Link className="text-link-primary-x3" to={{ pathname: `/app/admin/merchants/branches/view/${item.branch_uuid}` }}>{item.branch_name}</Link></List.Item>}
                                        />
                                    }
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Order Amount</div>
                                    <div className="display">P{ConvertToDecimal(orderDetails.order_total_amount)}</div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Order Date & Time</div>
                                    <div className="display">{DisplayDateTime(orderDetails.created_at)}</div>
                                </div>
                                <div className="bm-static-container">
                                    <div className="title">Status</div>
                                    <div className="display">{GetStatusLabels('orders', parseInt(orderDetails.order_status))}</div>
                                </div>
                                {
                                    orderDetails.order_status === 3 &&
                                    <div className="bm-static-container">
                                        <div className="title">Cancel Reason</div>
                                        <div className="display">{orderDetails.cancel_reason}</div>
                                    </div>
                                }
                            </Card>
                            <Card title="Customer Details" className="bm-card mb-4 mb-md-0">
                                {
                                    customerDetails !== null &&
                                    <div>
                                        <div className="bm-static-container">
                                            <div className="title">Customer Name</div>
                                            <div className="display"><h3><Link className="text-link-primary-x3" to={`/app/admin/customers/view/${orderDetails.customer_uuid}`}>{customerDetails.first_name} {customerDetails.last_name}</Link></h3></div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Address</div>
                                            <div className="display"><Link className="text-link-primary-x3" to={{ pathname: `https://maps.google.com/?q=${addressDetails.map_lat},${addressDetails.map_lng}` }} target="_blank" rel="noopener noreferrer">{addressDetails.street} {addressDetails.city_mun} {addressDetails.prov_region} {addressDetails.zip_code}</Link></div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Mobile Number</div>
                                            <div className="display">{customerDetails.mobile_number}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Email Address</div>
                                            <div className="display">{customerDetails.email_address}</div>
                                        </div>
                                        <div className="bm-static-container">
                                            <div className="title">Status</div>
                                            <div className="display">{GetStatusLabels('users', parseInt(customerDetails.user_status))}</div>
                                        </div>
                                    </div>
                                }
                                {
                                    customerDetails === null &&
                                    <Alert
                                        className="bm-alert mt-1 mb-4"
                                        message={'Error'}
                                        description={'No customer details found.'}
                                        type={'warning'}
                                    />
                                }
                            </Card>
                        </Col>
                    </Row>
                    {
                        orderDetails.order_status === 1 &&
                        <div className="py-5">
                            <h3 className="mb-4">Order Options</h3>
                            <Row gutter={24}>
                                <Col xs={24} md={12}>
                                    <Card title="Cancel Order" className="bm-card mb-4"
                                        actions={[
                                            <div className="text-end"><Button type="danger" className="bm-btn bm-btn-block" onClick={() => goCancelOrder()}>Cancel Order</Button></div>
                                        ]}>
                                        <Radio.Group onChange={onReasonChange} value={cancelReason}>
                                            <Space direction="vertical">
                                                <Radio value={'Merchant is closed.'}>Merchant is closed.</Radio>
                                                <Radio value={'Order item unavailable.'}>Order item unavailable.</Radio>
                                                <Radio value={'Merchant too busy.'}>Merchant too busy.</Radio>
                                                <Radio value={'Customer unreachable.'}>Customer unreachable.</Radio>
                                                <Radio value={'Others'}>Others</Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Card>
                                </Col>
                                <Col xs={24} md={12}>
                                    <Card title="Reassign Order" className="bm-card mb-4"
                                        actions={[
                                            <div className="text-end"><Button type="primary" className="bm-btn bm-btn-block" onClick={() => goReassignOrder()}>Reassign Order</Button></div>
                                        ]}>
                                        <Form layout={"vertical"} {...formLayout} className="bm-form">
                                            <Form.Item label="Delivery Partner">
                                                <Select
                                                    showSearch={true}
                                                    defaultValue={orderDetails.delivery_partner_uuid}
                                                    className="bm-select"
                                                    onChange={(value) => setReassignTo(value)}
                                                    filterOption={(input, option) => {
                                                        return option.props.children.toString().toLowerCase().includes(input.toLowerCase())
                                                    }}>
                                                    <Option value="" key="">select one</Option>
                                                    {
                                                        activeRiders &&
                                                        activeRiders.map(item => {
                                                            return <Option value={item.user_uuid} key={item.user_uuid}>{item.first_name} {item.last_name}</Option>
                                                        })
                                                    }
                                                </Select>
                                            </Form.Item>                                 
                                        </Form>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    }
                </div>
            }
            <Modal className="bm-modal" title="Cancel Order Confirmation" visible={isModalVisible} onCancel={handleClose} okText='Confirm Cancel' onOk={handleCancelOrder}>
                <Alert
                    className="bm-alert mt-1 mb-4"
                    message="Note"
                    description="The platform cannot reverse this action. Do you really want to CANCEL this order?"
                    type="danger"
                />
                <div className="bm-static-container">
                    <div className="title">Tracking Number</div>
                    <div className="display"><h3>{orderDetails.order_tracking_no}</h3></div>
                </div>
                <div className="bm-static-container">
                    <div className="title">Reason</div>
                    <div className="display">{cancelReason}</div>
                </div>
            </Modal>
        </PageLayout>
    )
}
