import axios from 'axios';

export const GetDeliveryPartners = async(service_area_uuid) => {
    try {
        let result;
        if (service_area_uuid === null) {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/delivery-partners');
        } else {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/delivery-partners/service-area/' + service_area_uuid);
        }
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetDeliveryPartnerDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/delivery-partners/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateDeliveryPartner = async(uuid, values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/delivery-partners/' + uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}