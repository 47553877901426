import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Row, Col, Card, Button, Image } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetBranchDetails } from '../../../../services/branches';
import { GetMerchantPartnersBranchUsers } from '../../../../services/merchantpartners';
import { GetServiceAreaDetails } from '../../../../services/serviceareas';
import { GetBranchOrders } from '../../../../services/orders';
import { GetOrderTrackings } from '../../../../services/trackings';
import usePageLoader from '../../../../hooks/usePageLoader';
import DefaultListingPhoto from '../../../../assets/img/default-listing-photo.png';
import { GetFile } from '../../../../services/files';
import { GetFoodCategoryLabels, GetStatusLabels } from '../../../../functions/Labels';
import { ConvertToDecimal } from '../../../../functions/Math';
import { DisplayDateTime } from '../../../../functions/Utilities';

export default function MerchantsBranchView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [serviceAreaDetails, setServiceAreaDetails] = useState(null);
    const [orderBranches, setOrderBranches] = useState(null);
    const [branchDetails, setBranchDetails] = useState(null);
    const [branchListingDetails, setBranchListingDetails] = useState(null);
    const [branchUsers, setBranchUsers] = useState(null);
    const [listingPhotoUrl, setListingPhotoUrl] = useState(DefaultListingPhoto);
    const { uuid }  = useParams();
    const history = useHistory();
    
    const getBranchDetails = async() => {
        showLoader();
        
        // get branch details
        const branchDetailsResult = await GetBranchDetails(uuid);
        setBranchDetails(branchDetailsResult.data.data);
        
        // get branch users
        const branchUsersResult = await GetMerchantPartnersBranchUsers(uuid);
        setBranchUsers(branchUsersResult.data.data);
        
        if (branchDetailsResult.data.data.listing_photo_file_uuid !== '') {
            const branchListingResult = await GetFile(branchDetailsResult.data.data.listing_photo_file_uuid);
            setBranchListingDetails(branchListingResult.data.data);
            setListingPhotoUrl(branchListingResult.data.data.file_url);
        }
        
        // get service area
        const serviceAreaResult = await GetServiceAreaDetails(branchDetailsResult.data.data.service_area_uuid);
        setServiceAreaDetails(serviceAreaResult.data.data);

        // get order branches
        const orderBranchesResult = await GetBranchOrders(uuid, 100);
        if (orderBranchesResult.data.data.length > 0) {
            let count = 0;
            orderBranchesResult.data.data.forEach((item, key) => {
                Promise.all([
                    GetOrderTrackings(item.order_uuid)
                ]).then(result => {
                    orderBranchesResult.data.data[key].tracking = result[0].data.data;
                })
                count++;
                if (count === orderBranchesResult.data.data.length) {
                    setTimeout(() => {
                        setOrderBranches(orderBranchesResult.data.data);
                        hideLoader();
                    }, 1000);
                }
            });
        } else {
            setOrderBranches(orderBranchesResult.data.data);
            hideLoader();
        }

        setDisplay(true);
        hideLoader();
    }

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'first_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/merchant-partners/view/${row.user_uuid}`}>{row.first_name} {row.last_name}</Link>,
        },
        {
            title: 'Mobile Number',
            dataIndex: 'user_mobile_number',
        },
        {
            title: 'Email',
            dataIndex: 'user_email_address',
            render: text => <a className="text-link-primary-x3" href={`mailto:${text}`}>{text}</a>,
        },
        {
            title: 'Status',
            dataIndex: 'user_status'
        }
    ];

    const renderCellTracking = (text, row, key) => {
        let trackingLatestKey = row.tracking.length - 1;
        return <strong>{GetStatusLabels('tracking', row.tracking[trackingLatestKey].tracking_code)}</strong>;
    }

    const columnsOrders = [
        {
            title: 'Tracking No',
            dataIndex: 'order_tracking_no',
            render: (text, row) => <Link className="text-link-primary-x3" to={{ pathname: `/app/admin/orders/view/${row.order_uuid}`, state: {orderDetails: row} }}>{text}</Link>,
        },
        {
            title: 'Order Date & Time',
            render: (text, row) => <span>{DisplayDateTime(row.created_at)}</span>,
        },
        {
            title: 'Tracking Status',
            render: renderCellTracking
        },
        {
            title: 'Order Amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_total_amount),
        },
        {
            title: 'Merchant Due',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_merchant_due_amount),
        },
        {
            title: 'Order Status',
            width: 150,
            dataIndex: 'order_status',
            render: text => GetStatusLabels('orders', parseInt(text)),
        }
    ];

    useEffect(() => {
        getBranchDetails();
        return function cleanup() {
            getBranchDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='merchants'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4 mb-md-0">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Branch Details" className="bm-card mb-4 mb-md-0">
                            <div className="bm-static-container">
                                <div className="title mb-2">Listing Photo</div>
                                <div className="display">
                                    <Image width={150} src={listingPhotoUrl} className="bm-image-rounded" />
                                </div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Service Area</div>
                                <div className="display">{serviceAreaDetails.service_area_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Branch Name</div>
                                <div className="display">{branchDetails.branch_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Branch Code</div>
                                <div className="display">{branchDetails.branch_code}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Food Category Types</div>
                                <div className="display">{GetFoodCategoryLabels(branchDetails.food_category_types)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Address Street</div>
                                <div className="display">{branchDetails.address_street}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">City / Municipality</div>
                                <div className="display">{branchDetails.address_city_mun}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Province / Region</div>
                                <div className="display">{branchDetails.address_prov_region}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Zip Code</div>
                                <div className="display">{branchDetails.address_zip_code}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Contact Person</div>
                                <div className="display">{branchDetails.contact_person}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Email</div>
                                <div className="display"><a href={`mailto:${branchDetails.email_address}`} className="text-link-primary-x3">{branchDetails.email_address}</a></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Telehone Number</div>
                                <div className="display">{branchDetails.tel_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Mobile Number</div>
                                <div className="display">{branchDetails.mobile_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('branches', parseInt(branchDetails.branch_status))}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }

            <h3 className="mb-4">Latest Orders</h3>
            {
                orderBranches &&
                <Table rowKey="merchant_uuid" dataSource={orderBranches} columns={columnsOrders} size={'middle'} className="bm-table" />
            }
            
            <h3 className="mb-4 mt-4">Branch Users List</h3>
            {
                branchUsers &&
                <Table rowKey="merchant_uuid" dataSource={branchUsers} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
