import React from 'react';
import { Layout, Card, Form, Input, Button } from 'antd';
import Logo from '../../assets/img/rideeat-logo-dark.png';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useHistory } from 'react-router-dom';
import usePageLoader from '../../hooks/usePageLoader';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Debounce } from '../../functions/Utilities';
import { GetMerchantDetails } from '../../services/merchants';

export default function Login() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const { Content, Footer } = Layout;
    const { Login } = useAuth();
    const history = useHistory();

    const submitHandler = async(values) => {
        showLoader();
        try {
            const result = await Login(values);
            switch(result.data.status) {
                case 'success':
                    switch(result.data.data.user_type_code) {
                        case 'A004':
                            const resultMerchant = await GetMerchantDetails(result.data.data.other_details.merchant_uuid);
                            if (resultMerchant.data.data.merchant_status === 1) {
                                history.push('/');
                            } else {
                                Debounce(NotificationManager.error('There is a problem with your account. Please contact support', 'Sorry', 2000), 2000);
                            }
                            break;
                        case 'A001':
                            history.push('/');
                            break;
                        default:
                            Debounce(NotificationManager.error('Email and/or password you provided is incorrect. Please try again.', 'Sorry', 2000), 2000);
                            break;
                    }
                    break;
                default:
                    Debounce(NotificationManager.error('Email and/or password you provided is incorrect. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    return (
        <Layout className="bm-layout bm-static centered">
            { loader }
            <NotificationContainer/>
            <Content>
                <div className="bm-auth-container">
                    <img src={Logo} className="bm-static-logo" alt="ride eat" />
                    <Card title="Login" className="bm-card" bordered={false}>
                        <Form layout={"vertical"} className="bm-form" onFinish={submitHandler}>
                            <Form.Item label="Email"
                                name="email_address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your email address.',
                                    },
                                ]}>
                                <Input placeholder="email" />
                            </Form.Item>
                            <Form.Item label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter your password.',
                                    },
                                ]}>
                                <Input placeholder="password" type="password" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" className="bm-btn w-100 mt-2" htmlType="submit">
                                    Submit
                                </Button>
                            </Form.Item>
                            <div className="text-center">
                                <Link className="text-link-primary-x3" to="./forgot-password">Forgot password</Link>
                            </div>
                        </Form>
                    </Card>
                    <Button type="default" className="bm-btn w-100 mt-4" onClick={() => history.push('/register/merchant')}>
                        Merchant Registration
                    </Button>
                </div>
            </Content>
            <Footer className="bm-static-footer">
            RIDE EAT is a Trademark of Ride Eat Technologies Corporation. Copyright © { new Date().getFullYear() }.
            </Footer>
        </Layout>
    );
}
