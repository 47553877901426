import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { UpdateRequest } from '../../../../services/requests';
import { AddWalletTransaction } from '../../../../services/wallets';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';
import { ConvertToDecimal } from '../../../../functions/Math';

export default function RequestUpdate() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const history = useHistory();
    const requestDetails = history.location.state.requestDetails;
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            const requestValues = {
                request_status: values.request_status
            }
            await UpdateRequest(requestValues, requestDetails.request_uuid);
            if (values.request_status === '2') {
                // add to transaction
                const walletValues = {
                    user_uuid: requestDetails.user_uuid,
                    transfer_type_code: "I008",
                    reference_uuid: requestDetails.request_uuid,
                    movement_type: "credit",
                    transaction_amount: requestDetails.request_amount,
                    wallet_transaction_status: 1
                }
                const walletResult = await AddWalletTransaction(walletValues);
                switch(walletResult.data.status) {
                    case 'success':
                        Debounce(NotificationManager.success('Request has been updated.', 'Success', 2000), 2000);
                        setSuccess(true);
                        break;
                    case 'error':
                        Debounce(NotificationManager.error('Request does not exist.', 'Error', 2000), 2000);
                        break;
                    default:
                        Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                        break;
                }
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='requests'>
            { loader }
            {
                <Row>
                    <Col xs={24} lg={{span: 18, offset: 2}} xl={{span: 12, offset: 6}}>
                        <Card title="Update Request" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Request Updated"
                                    description="Request has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/admin/requests/list')}>
                                            Back to List
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                                initialValues={{
                                    'requester_name': requestDetails.first_name + ' ' + requestDetails.last_name,
                                    'request_code': requestDetails.request_code,
                                    'request_amount': 'P' + ConvertToDecimal(requestDetails.request_amount),
                                    'request_status': String(requestDetails.request_status)
                                }}>
                                <Form.Item label="Requester's Name"
                                    name="requester_name">
                                    <Input placeholder="requesters name" disabled={true} />
                                </Form.Item>
                                <Form.Item label="Request Code"
                                    name="request_code">
                                    <Input placeholder="request code" disabled={true} />
                                </Form.Item>
                                <Form.Item label="Amount"
                                    name="request_amount">
                                    <Input placeholder="amount" disabled={true} />
                                </Form.Item>
                                <Form.Item label="Status"
                                    name="request_status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select status.',
                                        },
                                    ]}>
                                    <Select>
                                        <Option value="1">Pending</Option>
                                        <Option value="2">Approve</Option>
                                        <Option value="3">Disapprove</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
