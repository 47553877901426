import React, { useEffect } from 'react';
import { Layout, Menu, Dropdown, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import Profile from '../icons/Profile';
import ProfileDocument from '../icons/ProfileDocument';
import Exit from '../icons/Exit';
import MenuBars from '../icons/MenuBars';
import Lock from '../icons/Lock';
import useWindowDimensions from '../../hooks/useWindowDimensions';

export default function PageHeader({currentUser, sideCollapsed, setSideCollapsed}) {
    const { Header } = Layout;
    const history = useHistory();
    const { width } = useWindowDimensions();
    const headerMenu = (
        <Menu className="header-icons">
            <Menu.Item key="view" icon={<Profile />} onClick={() => history.push('/app/profile/view')}>View Profile</Menu.Item>
            <Menu.Item key="update" icon={<ProfileDocument />} onClick={() => history.push('/app/profile/update')}>Update Profile</Menu.Item>
            <Menu.Item key="password" icon={<Lock />} onClick={() => history.push('/app/profile/password')}>Change Password</Menu.Item>
            <Menu.Item key="logout" icon={<Exit />} onClick={() => history.push('/logout')}>Logout</Menu.Item>
        </Menu>
    );

    const handleToggle = () => {
        (sideCollapsed === true) ? setSideCollapsed(false) : setSideCollapsed(true);
    }

    useEffect(() => {
        const fetchBusiness = () => {
            (width < 767.98) ? setSideCollapsed(true) : setSideCollapsed(false);
        }
        return function cleanup() {
            fetchBusiness();
        }
        // eslint-disable-next-line
    }, [])

    return (
        <Header>
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center">
                    <Button className="btn-icon btn-icon-primary me-2" type="primary" icon={<MenuBars />} onClick={() => handleToggle()}></Button>
                    <h3>Welcome {currentUser.first_name}</h3>
                </div>
                <Dropdown trigger={['click']} overlay={headerMenu} className="ms-auto">
                    <div className="links-container">
                        <Button className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            <Profile />
                        </Button>
                    </div>
                </Dropdown>
            </div>
        </Header>
    )
}
