import axios from 'axios';

export const GetItemsVariations = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/items-variations/item/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddVariation = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/items-variations', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetVariationDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/items-variations/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateVariation = async(values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/items-variations/' + values.variation_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const ImportVariations = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/items-variations/import', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const BatchPriceUpdate = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/items-variations/batch', values);
        return result;
    } catch (error) {
        throw error;
    }
}