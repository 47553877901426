import axios from 'axios';

export const GetCurrentOrders = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/current/branch/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetBranchOrders = async(uuid, limit) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/branch/' + uuid + '/limit/' + limit);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetMonthlyOrders = async(uuid, year, month) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/monthly/branch/' + uuid + '/year/' + year + '/month/' + month);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetLatestOrders = async(service_area_uuid, limit) => {
    try {
        let result;
        if (service_area_uuid === null) {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/latest/limit/' + limit);
        } else {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/latest/service-area/' + service_area_uuid + '/limit/' + limit);
        }
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetPastOrders = async(service_area_uuid, customer_uuid, limit) => {
    try {
        let result;
        if (service_area_uuid === null) {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/past/' + customer_uuid + '/limit/' + limit);
        } else {
            result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/past/' + customer_uuid + '/service-area/' + service_area_uuid + '/limit/' + limit);
        }
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateOrder = async(values, uuid) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/orders/' + uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetUserOrders = async(user_uuid, limit) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/user/' + user_uuid + '/limit/' + limit);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetOrdersStats = async(dateFrom, dateTo, uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/count/' + dateFrom + '/' + dateTo + '/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetOrdersDailyStats = async(dateFrom, dateTo, uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/orders/daily/' + dateFrom + '/' + dateTo + '/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}