export const Regions = [
    {
        "id": 1,
        "code": "NCR",
        "regionName": "National Capital Region - NCR"
    },
    {
        "id": 2,
        "code": "CAR",
        "regionName": "Cordillera Administrative Region - CAR"
    },
    {
        "id": 3,
        "code": "RI",
        "regionName": "Ilocos Region - Region I"
    },
    {
        "id": 4,
        "code": "RII",
        "regionName": "Cagayan Valley - Region II"
    },
    {
        "id": 5,
        "code": "RIII",
        "regionName": "Central Luzon - Region III"
    },
    {
        "id": 6,
        "code": "RIVA",
        "regionName": "Calabarzon - Region IVA"
    },
    {
        "id": 7,
        "code": "RIVB",
        "regionName": "Mimaropa - Region IVB"
    },
    {
        "id": 8,
        "code": "RV",
        "regionName": "Bicol Region - Region V"
    },
    {
        "id": 9,
        "code": "RVI",
        "regionName": "Western Visayas - Region RVI"
    },
    {
        "id": 10,
        "code": "RVII",
        "regionName": "Central Visayas - Region VII"
    },
    {
        "id": 11,
        "code": "RVIII",
        "regionName": "Eastern Visayas - Region VIII"
    },
    {
        "id": 12,
        "code": "RIX",
        "regionName": "Zamboanga Peninsula - Region IX"
    },
    {
        "id": 13,
        "code": "RX",
        "regionName": "Northern Mindanao - Region X"
    },
    {
        "id": 14,
        "code": "RXI",
        "regionName": "Davao Region - Region XI"
    },
    {
        "id": 15,
        "code": "RXII",
        "regionName": "Soccsksargen - Region XII"
    },
    {
        "id": 16,
        "code": "RXIII",
        "regionName": "Caraga - Region XIII"
    },
    {
        "id": 17,
        "code": "BARMM",
        "regionName": "Bangsamoro - BARMM"
    }
]
