import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, Switch, Button, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { AddGroups } from '../../../../services/addongroups';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function GroupsAdd({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const history = useHistory();
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            (values.is_required === true) ? values.is_required = 1: values.is_required = 0;
            (values.is_multiple === true) ? values.is_multiple = 1: values.is_multiple = 0;
            const formattedValues = {
                ...values,
                merchant_uuid: currentUser.other_details.merchant_uuid,
                add_on_group_status: 1
            }
            const result = await AddGroups(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Add-on group has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Add-on group already exists. Please use a different add-on group name.', 'Add-on Group Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='add-ons'>
            { loader }
            {
                <Row>
                    <Col xs={24} lg={{span: 18, offset: 2}} xl={{span: 12, offset: 6}}>
                        <Card title="Add Groups" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="New Add-on Group Added"
                                    description="Add-on has been added successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/merchants/add-ons/list')}>
                                            Back to List
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Add-On Group Name"
                                    name="add_on_group_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the add-on group name.',
                                        },
                                    ]}>
                                    <Input placeholder="add-on group name" />
                                </Form.Item>
                                <Form.Item label="Required"
                                    name="is_required">
                                    <Switch defaultChecked={false} />
                                </Form.Item>
                                <Form.Item label="Multiple"
                                    name="is_multiple">
                                    <Switch defaultChecked={false} />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
