import React, { useEffect, useState } from 'react';
import PageLayout from '../../../../components/layout/PageLayout';
import { Form, Row, Col, Card, Statistic, Divider, Button, DatePicker } from 'antd';
import { GetOrdersDailyStats } from '../../../../services/orders';
import { GetUsersRegistrationStats, GetUserActiveStats } from '../../../../services/users';
import moment from 'moment-timezone';
import { FilterObject } from '../../../../functions/Utilities';
import { Line } from '@ant-design/charts';
import usePageLoader from '../../../../hooks/usePageLoader';

export default function TrendsOrders({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [dateStart, setDateStart] = useState(moment().subtract(6, 'days').tz('Asia/Manila').format('YYYY-MM-DD'));
    const [dateEnd, setDateEnd] = useState(moment().tz('Asia/Manila').format('YYYY-MM-DD'));
    const [ordersStatsFormatted, setOrdersStatsFormatted] = useState(null);
    const { RangePicker } = DatePicker;
    const dateFormat = 'YYYY/MM/DD';
    const serviceArea = (currentUser.other_details.service_area_uuid) ? currentUser.other_details.service_area_uuid : 'all';

    const getPageData = async() => {
        showLoader();
        setOrdersStatsFormatted(null);
        Promise.all([
            GetOrdersDailyStats(dateStart, dateEnd, serviceArea),
        ]).then(result => {
            const loopStart = moment(dateStart);
            const loopEnd = moment(dateEnd);
            const ordersFormatted = []
            while (loopStart.isSameOrBefore(loopEnd, 'day')) {
                let filterResult = FilterObject(result[0].data.data, loopStart.format('YYYY-MM-DD'), 'date');
                ordersFormatted.push({
                    'date': loopStart.format('YYYY-MM-DD'),
                    'order_count': filterResult.length > 0 ? filterResult[0].order_count : 0
                })
                loopStart.add(1, 'days');
            }
            setOrdersStatsFormatted(ordersFormatted);
            hideLoader();
        });
    }

    const changeRange = (date) => {
        setDateStart(date[0].format('YYYY-MM-DD'));
        setDateEnd(date[1].format('YYYY-MM-DD'));
    }

    const handleSubmit = () => {
        getPageData();
    }

    const config = {
        data: ordersStatsFormatted,
        xField: 'date',
        yField: 'order_count',
        meta: {
            date: {
                alias: 'Date'
            },
            order_count: {
                alias: 'Orders'
            }
        },
        lineStyle: {
            stroke: '#000'
        },
        point: {
            size: 8,
            shape: 'circle',
            color: '#c52c70'
        },
    };

    useEffect(() => {
        getPageData();
        return function cleanup() {
            getPageData();
        }
    }, []);

    return (
        <PageLayout activeLink='dashboard'>
            { loader }
            <h4 className="mb-4">Trends</h4>
            <div>
                <div className="d-flex w-100">
                    <h3 className="mb-4">Orders</h3>
                    <div className="ms-auto buttons">
                        <Form layout={"inline"} className="bm-form" onFinish={handleSubmit}>
                            <Form.Item name="orders_range">
                                <RangePicker
                                    defaultValue={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                                    value={[moment(dateStart, dateFormat), moment(dateEnd, dateFormat)]}
                                    onChange={(date) => changeRange(date)}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                    Change
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
                <div>
                    {
                        ordersStatsFormatted &&
                        <Line {...config} />
                    }
                </div>
            </div>
        </PageLayout>
    )
    
}
