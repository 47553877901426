import axios from 'axios';

export const AddOrderBranches = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/order-branches/batch', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetOrderBranches = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/order-branches/order/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}