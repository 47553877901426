import React, { useEffect, useState } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Row, Col, Card, Button, Table, Image } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetServiceAreaDetails } from '../../../../services/serviceareas';
import { GetFeatures, UpdateFeatures } from '../../../../services/features';
import { GetSpotlights } from '../../../../services/serviceareaspotlights';
import { ConvertToDecimal } from '../../../../functions/Math';
import { GetRegionLabel, GetStatusLabels } from '../../../../functions/Labels';
import usePageLoader from '../../../../hooks/usePageLoader';
import arrayMove from 'array-move';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function ServiceAreasView() {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [serviceAreaDetails, setServiceAreaDetails] = useState(null);
    const [features, setFeatures] = useState(null);
    const [spotlights, setSpotlights] = useState(null);
    const history = useHistory();
    const { uuid }  = useParams();
    const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
    const SortableItem = sortableElement(props => <tr {...props} />);
    const SortableContainer = sortableContainer(props => <tbody {...props} />);
    
    const getServiceAreaDetails = async() => {
        showLoader();
        Promise.all([
            GetServiceAreaDetails(uuid),
            GetFeatures(uuid),
            GetSpotlights(uuid)
        ]).then(result => {
            setServiceAreaDetails(result[0].data.data);
            result[1].data.data.forEach(function(item, index) {
                result[1].data.data[index].index = index;
            });
            setFeatures(result[1].data.data);
            setSpotlights(result[2].data.data);
            setDisplay(true);
        });
        hideLoader();
    }

    useEffect(() => {
        getServiceAreaDetails();
        return function cleanup() {
            getServiceAreaDetails();
        }
        // eslint-disable-next-line
    }, []);
   
    const featureColumns = [
        {
            title: 'Sort',
            dataIndex: 'sort',
            width: 60,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: 'Feature Title',
            dataIndex: 'feature_title',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/service-areas/features/view/${row.feature_uuid}`}>{text}</Link>,
        },
        {
            title: 'Image',
            render: (text, row) => <Image className="bm-image-rounded" width={100} src={row.file_url} />,
        },
        {
            title: 'Status',
            width: 110,
            render: (text, row) => GetStatusLabels('features', row.feature_status)
        }
    ];

    const spotlightColumns = [
        {
            title: 'Spotlight Title',
            dataIndex: 'spotlight_title',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/service-areas/spotlights/view/${row.spotlight_uuid}`}>{text}</Link>,
        },
        {
            title: 'Alt Title',
            dataIndex: 'spotlight_alt_title'
        },
        {
            title: 'Image',
            render: (text, row) => <Image className="bm-image-rounded" width={100} src={row.file_url} />,
        },
        {
            title: 'Status',
            width: 110,
            render: (text, row) => GetStatusLabels('spotlights', row.spotlight_status)
        }
    ];

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={handleSortEnd}
            {...props}
        />
    );

    const DraggableBodyRow = ({className, style, ...restProps}) => {
        const dataSource = features;
        const index = dataSource.findIndex(x => x.index === restProps['data-row-key']);
        return <SortableItem index={index} {...restProps} />;
    };

    const handleSortEnd = ({oldIndex, newIndex}) => {
        const dataSource = features;
        if (oldIndex !== newIndex) {
            const newData = arrayMove([].concat(dataSource), oldIndex, newIndex).filter(el => !!el);
            updateOrder(newData);
        }
    }

    const updateOrder = (values) => {
        showLoader();
        Promise.all(values.map(async (item, key) => {
            const formattedValues = {
                feature_uuid: item.feature_uuid,
                feature_display_order: key + 1
            }
            return await UpdateFeatures(formattedValues);
        })).then(result => {
            if (values.length === result.length) {
                getServiceAreaDetails();
                Debounce(NotificationManager.success('Feature items has been updated.', 'Success', 2000), 2000);
            } else {
                Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
            }
            hideLoader();
        });
    }

    return (
        <PageLayout activeLink='service-areas'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Service Area Details"
                                className="bm-card mb-4 mb-md-0"
                                actions={[
                                    <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/admin/service-areas/update/' + uuid, state: {serviceAreaDetails}})}>Update</Button></div>
                                ]}>
                            <div className="bm-static-container">
                                <div className="title">Service Area Code</div>
                                <div className="display">{serviceAreaDetails.service_area_code}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Service Area Name</div>
                                <div className="display">{serviceAreaDetails.service_area_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Region</div>
                                <div className="display">{GetRegionLabel(serviceAreaDetails.region_name)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Radius</div>
                                <div className="display">{ConvertToDecimal(serviceAreaDetails.service_area_radius)} Km</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Delivery Fee (Min)</div>
                                <div className="display">{'P' + ConvertToDecimal(serviceAreaDetails.delivery_min_fee)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Delivery Radius (Min)</div>
                                <div className="display">{'P' + ConvertToDecimal(serviceAreaDetails.delivery_min_radius)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Excess Fee (per Km)</div>
                                <div className="display">{'P' + ConvertToDecimal(serviceAreaDetails.delivery_excess_per_km)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Delivery Fee Commission</div>
                                <div className="display">{serviceAreaDetails.delivery_fee_commission + '%'}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Booking Fee</div>
                                <div className="display">{'P' + ConvertToDecimal(serviceAreaDetails.booking_fee)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('service-areas', serviceAreaDetails.service_area_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }
            <Row gutter={24}>
                <Col xs={24} md={24} lg={12}>
                    <div className="d-flex w-100 mt-5">
                        <h3 className="mb-4">Features</h3>
                        <div className="ms-auto buttons">
                            <Button type="primary" className="bm-btn" onClick={() => history.push({pathname: '/app/admin/service-areas/features/add/' + uuid, state: {serviceAreaDetails}})}>Add Feature Image</Button>
                        </div>
                    </div>
                    {
                        features &&
                        <Table rowKey="index"
                            dataSource={features}
                            columns={featureColumns}
                            size={'middle'}
                            className="bm-table"
                            pagination={false}
                            components={{
                                body: {
                                    wrapper: DraggableContainer,
                                    row: DraggableBodyRow
                                },
                            }}
                        />
                    }
                </Col>
                <Col xs={24} md={24} lg={12}>
                    <div className="d-flex w-100 mt-5">
                        <h3 className="mb-4">Spotlights</h3>
                        <div className="ms-auto buttons">
                            <Button type="primary" className="bm-btn" onClick={() => history.push({pathname: '/app/admin/service-areas/spotlights/add/' + uuid, state: {serviceAreaDetails}})}>Add Spotlight</Button>
                        </div>
                    </div>
                    {
                        spotlights &&
                        <Table rowKey="index"
                            dataSource={spotlights}
                            columns={spotlightColumns}
                            size={'middle'}
                            className="bm-table"
                            pagination={false}
                        />
                    }
                </Col>
            </Row>
            
        </PageLayout>
    )
}