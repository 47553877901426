import axios from 'axios';

export const GetAddOns = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/add-ons/group/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const AddAddOn = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/add-ons', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetAddOnDetails = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/add-ons/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const UpdateAddOn = async (values) => {
    try {
        const result = await axios.put(process.env.REACT_APP_API_BASE_URL + '/add-ons/' + values.add_on_uuid, values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const ImportAddOns = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/add-ons/import', values);
        return result;
    } catch (error) {
        throw error;
    }
}

export const BatchAddOnsUpdate = async(values) => {
    try {
        const result = await axios.post(process.env.REACT_APP_API_BASE_URL + '/add-ons/batch', values);
        return result;
    } catch (error) {
        throw error;
    }
}