import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Button, Alert, Select, Upload } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { UploadFiles } from '../../../../services/files';
import { UpdateMerchant } from '../../../../services/merchants';
import { GetServiceAreas } from '../../../../services/serviceareas';
import { MobileNumberFormat } from '../../../../functions/Labels';
import { Debounce } from '../../../../functions/Utilities';
import usePageLoader from '../../../../hooks/usePageLoader';
import ImgCrop from 'antd-img-crop';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

export default function MerchantsUpdate({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const [fileList, setFileList] = useState([]);
    const [newLogo, setNewLogo] = useState(null);
    const [serviceAreas, setServiceAreas] = useState(null);
    const [newLogoNotice, setNewLogoNotice] = useState(null);
    const history = useHistory();
    const merchantDetails = history.location.state.merchantDetails;
    const merchantLogoDetails = history.location.state.merchantLogoDetails;

    const getPageData = async() => {
        const serviceAreaResult = await GetServiceAreas();
        setServiceAreas(serviceAreaResult.data.data);
    }
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                email_address: values.merchant_email_address,
                merchant_uuid: merchantDetails.merchant_uuid,
                mobile_number: MobileNumberFormat('save', values.mobile_number),
                is_free_delivery: parseInt(values.is_free_delivery),
                min_free_delivery: values.min_free_delivery * 100,
                merchant_status: parseInt(values.merchant_status)
            }
            if (newLogo !== null) {
                formattedValues.merchant_logo_uuid = newLogo
            }
            const result = await UpdateMerchant(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Merchant has been updated.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Merchant already exists.', 'Merchant Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const uploadFile = async(file) => {
        showLoader();
        try {
            const formattedValues = {
                file_url: file.secure_url,
                file_type: file.resource_type,
                file_name: file.asset_id,
                file_description: '',
                file_status: 1
            }
            const result = await UploadFiles(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Merchant logo is now uploaded.', 'Success', 2000), 2000);
                    const logoItem = {
                        uid: file.asset_id,
                        name: file.asset_id,
                        url: file.secure_url
                    }
                    setFileList([logoItem]);
                    setNewLogo(result.data.file_uuid);
                    setNewLogoNotice(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleOnChange = async({file, fileList: newFileList }) => {
        setFileList(newFileList);
        showLoader();
        if (file.status !== 'uploading') {
            await getBase64(file.originFileObj, result => {
                let formData = new FormData();
                formData.append('file', result);
                formData.append('upload_preset', 'ixal6zkh');
                axios.post('https://api.cloudinary.com/v1_1/falcomtech/image/upload', formData)
                    .then(response => {
                        if (response.status === 200) {
                            uploadFile(response.data);
                            hideLoader();
                        }
                    })
            });
        }
    }

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 16 } }
    }

    useEffect(() => {
        if (merchantLogoDetails !== null) {
            const logoItem = {
                uid: merchantLogoDetails.file_uuid,
                name: merchantLogoDetails.file_name,
                url: merchantLogoDetails.file_url
            }
            setFileList([logoItem]);
        }
        getPageData();
        return () => {
            getPageData();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='merchants'>
            { loader }
            {
                <Row>
                    <Col xs={24} lg={{span: 18, offset: 2}} xl={{span: 12, offset: 6}}>
                        <Card title="Update Merchant" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Merchant Updated"
                                    description="Merchant has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.goBack()}>Back</Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                                initialValues={{
                                    'merchant_logo': merchantDetails.merchant_logo_uuid,
                                    'merchant_name': merchantDetails.merchant_name,
                                    'company_name': merchantDetails.company_name,
                                    'contact_person': merchantDetails.contact_person,
                                    'merchant_email_address': merchantDetails.email_address,
                                    'mobile_number': MobileNumberFormat('view', merchantDetails.mobile_number),
                                    'commission_rate': merchantDetails.commission_rate,
                                    'price_rate': merchantDetails.price_rate,
                                    'service_area_uuid': merchantDetails.service_area_uuid,
                                    'compliance_code': merchantDetails.compliance_code,
                                    'is_free_delivery': String(merchantDetails.is_free_delivery),
                                    'min_free_delivery': merchantDetails.min_free_delivery / 100,
                                    'merchant_status': String(merchantDetails.merchant_status)
                                }}>
                                <Form.Item label="Merchant Logo"
                                    name="merchant_logo">
                                    <ImgCrop modalTitle="Crop Image" aspect={1}>
                                        <Upload name="image"
                                            listType="picture-card"
                                            accept="image/png, image/jpeg"
                                            fileList={fileList}
                                            onChange={handleOnChange}
                                            beforeUpload={false}
                                            onRemove={false}>
                                            {fileList.length < 2 && 'Replace'}
                                        </Upload>
                                    </ImgCrop>
                                    <Input className="d-none" />
                                    {
                                        newLogoNotice &&
                                        <Alert
                                            className="bm-alert mt-2"
                                            message="Notice"
                                            description="Merchant logo has not been saved to the database yet. You need to submit the form in order to update the logo."
                                            type="default"
                                        />
                                    }
                                </Form.Item>
                                <Form.Item label="Merchant Name"
                                    name="merchant_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter merchant name.',
                                        },
                                    ]}
                                    >
                                    <Input placeholder="merchant name" />
                                </Form.Item>
                                <Form.Item label="Company Name"
                                    name="company_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company name.',
                                        },
                                    ]}
                                    >
                                    <Input placeholder="company name" />
                                </Form.Item>
                                <Form.Item label="Contact Person"
                                    name="contact_person"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the contact person.',
                                        },
                                    ]}
                                    >
                                    <Input placeholder="full name of contact person" />
                                </Form.Item>
                                <Form.Item label="Email"
                                    name="merchant_email_address"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter email address.',
                                        },
                                    ]}
                                    >
                                    <Input placeholder="email address" />
                                </Form.Item>
                                <Form.Item label="Mobile Number"
                                    name="mobile_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a valid mobile number.',
                                        },
                                    ]}
                                    >
                                    <Input placeholder="09" />
                                </Form.Item>
                                <Form.Item label="Commission Rate"
                                    name="commission_rate"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the commission rate (percent).',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0" precision={0} step={1} />
                                </Form.Item>
                                <Form.Item label="Price Rate"
                                    name="price_rate"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the price rate (percent).',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0" precision={0} step={1} />
                                </Form.Item>
                                <Form.Item label="Service Area"
                                    name="service_area_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please please select service area.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <Option value="">select one</Option>
                                        {
                                            serviceAreas &&
                                            serviceAreas.map(item => {
                                                return <Option value={item.service_area_uuid}>{item.service_area_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Compliance Code"
                                    name="compliance_code">
                                    <Select>
                                        <Option value="D001">Compliant</Option>
                                        <Option value="D002">Non-Compliant</Option>
                                        <Option value="D003">Compliant but Restricted</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Free Delivery"
                                    name="is_free_delivery"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select one.',
                                        },
                                    ]}>
                                    <Select>
                                        <Option value="0">No</Option>
                                        <Option value="1">Yes</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item label="Min of Free Delivery"
                                    name="min_free_delivery"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the min price free delivery.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Status"
                                    name="merchant_status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select status.',
                                        },
                                    ]}>
                                    <Select>
                                        <Option value="1">Active</Option>
                                        <Option value="2">Pending</Option>
                                        <Option value="0">Inactive</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
