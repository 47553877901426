import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Switch, Button, Alert, Divider, Upload } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { AddItems } from '../../../../services/items';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';
import ImgCrop from 'antd-img-crop';
import { UploadFiles } from '../../../../services/files';
import axios from 'axios';

export default function ItemsAdd({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const { TextArea } = Input;
    const history = useHistory();
    const [fileListPhoto, setFileListPhoto] = useState([]);
    const [newPhoto, setNewPhoto] = useState('');
    const [newPhotoNotice, setNewPhotoNotice] = useState(null);
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            // textarea undefined when user skips field
            if (values.item_description === undefined) {
                values.item_description = "";
            }
            if (values.variation_description === undefined) {
                values.variation_description = "";
            }
            (values.is_bestseller === true) ? values.is_bestseller = 1: values.is_bestseller = 0;
            const formattedValues = {
                ...values,
                merchant_uuid: currentUser.other_details.merchant_uuid,
                item_photo_file_uuid: newPhoto,
                item_status: 1,
                variation_price: values.variation_price * 100,
                variation_status: 1
            }
            const result = await AddItems(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Item has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Item already exists. Please use a different item name.', 'Item Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 16 } }
    }

    const uploadFile = async(file) => {
        showLoader();
        try {
            const formattedValues = {
                file_url: file.secure_url,
                file_type: file.resource_type,
                file_name: file.asset_id,
                file_description: '',
                file_status: 1
            }
            const result = await UploadFiles(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Banner photo is now uploaded.', 'Success', 2000), 2000);
                    const bannerItem = {
                        uid: file.asset_id,
                        name: file.asset_id,
                        url: file.secure_url
                    }
                    setFileListPhoto([bannerItem]);
                    setNewPhoto(result.data.file_uuid);
                    setNewPhotoNotice(true);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    }

    const getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    const handleOnChangePhoto = async({file, fileList: newFileList }) => {
        setFileListPhoto(newFileList);
        showLoader();
        if (file.status !== 'uploading') {
            await getBase64(file.originFileObj, result => {
                let formData = new FormData();
                formData.append('file', result);
                formData.append('upload_preset', 'ixal6zkh');
                axios.post('https://api.cloudinary.com/v1_1/falcomtech/image/upload', formData)
                    .then(response => {
                        if (response.status === 200) {
                            uploadFile(response.data);
                            hideLoader();
                        }
                    })
            });
        }
    }

    return (
        <PageLayout activeLink='items'>
            { loader }
            {
                <Row>
                    <Col xs={24} lg={{span: 18, offset: 2}} xl={{span: 12, offset: 6}}>
                        <Card title="Add Items" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="New Item Added"
                                    description="Item has been added successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/merchants/items/list')}>
                                            Back to List
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Item Name"
                                    name="item_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the item name.',
                                        },
                                    ]}>
                                    <Input placeholder="item name" />
                                </Form.Item>
                                <Form.Item label="Item Description"
                                    name="item_description">
                                    <TextArea rows={4} placeholder="item description" />
                                </Form.Item>
                                <Form.Item label="Item Photo"
                                    name="item_photo">
                                    <ImgCrop modalTitle="Crop Image" aspect={1.77} quality={.8}>
                                        <Upload name="image"
                                            listType="picture-card"
                                            accept="image/png, image/jpeg"
                                            fileList={fileListPhoto}
                                            onRemove={false}
                                            beforeUpload={false}
                                            onChange={handleOnChangePhoto}>
                                            {fileListPhoto.length < 2 && 'Add'}
                                        </Upload>
                                    </ImgCrop>
                                    <Input className="d-none" />
                                    {
                                        newPhotoNotice &&
                                        <Alert
                                            className="bm-alert mt-2"
                                            message="Notice"
                                            description="Menu item photo has not been saved to the database yet. You need to submit the form in order to save the image."
                                            type="default"
                                        />
                                    }
                                </Form.Item>
                                <Divider />
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Note"
                                    description="For new items, you need to add at least one item variation. Fill up the fields below."
                                    type="default"
                                />
                                <Form.Item label="Variation Name"
                                    name="variation_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the variation name.',
                                        },
                                    ]}>
                                    <Input placeholder="variation name" />
                                </Form.Item>
                                <Form.Item label="Variation Description"
                                    name="variation_description">
                                    <TextArea rows={4} placeholder="variation description" />
                                </Form.Item>
                                <Form.Item label="Price"
                                    name="variation_price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the variation price.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Best Seller"
                                    name="is_bestseller">
                                    <Switch />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
