import React, { useState } from 'react';
import { Layout, Row, Col, Card, Form, Input, Button, Divider, Alert, Select } from 'antd';
import Logo from '../../assets/img/rideeat-logo-dark.png';
import { useAuth } from '../../contexts/AuthContext';
import usePageLoader from '../../hooks/usePageLoader';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Debounce, FormatMobileNumber } from '../../functions/Utilities';

export default function RegisterDelivery() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const { Content, Footer } = Layout;
    const { RegisterDelivery } = useAuth();
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                email_address: values.email_address.trim(),
                service_area_uuid: '',
                plate_id: null,
                is_on_shift: 0,
                is_available: 1,
                mobile_number_formatted: FormatMobileNumber(values.mobile_number.trim())
            }
            const result = await RegisterDelivery(formattedValues);
            switch(result.data.status) {
                case 'success':
                    scrollToTop();
                    Debounce(NotificationManager.success('Your information has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Delivery Partner email address already exists. Please use a different email.', 'Email Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, md: { offset: 8, span: 16 } }
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    return (
        <Layout className="bm-layout bm-static centered">
            { loader }
            <NotificationContainer/>
            <Content>
                <div className="bm-auth-container">
                    <img src={Logo} className="bm-static-logo" alt="ride eat" />
                    <Card title="Delivery Partner Registration" className="bm-card wide" bordered={false}>
                        {
                            success &&
                            <Alert
                                className="bm-alert mt-2 mb-3"
                                message="Congratulations!"
                                description="Your delivery partner profile and information are now saved. One of our support team will contact you within 24 hours for the next steps."
                                type="success"
                            />
                        }
                        <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                            <Row gutter={[16, 24]}>
                                <Col xs={24} md={24} lg={12}>
                                    <Divider orientation="left">User Details</Divider>
                                    <Form.Item label="Email"
                                        name="email_address"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your email address.',
                                            },
                                        ]}>
                                        <Input placeholder="email address" />
                                    </Form.Item>
                                    <Form.Item label="First Name"
                                        name="first_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your first name.',
                                            },
                                        ]}>
                                        <Input placeholder="first name" />
                                    </Form.Item>
                                    <Form.Item label="Last Name"
                                        name="last_name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your last name.',
                                            },
                                        ]}>
                                        <Input placeholder="last name" />
                                    </Form.Item>
                                    <Form.Item label="Mobile Number"
                                        name="mobile_number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a valid mobile number.',
                                            },
                                        ]}
                                        >
                                        <Input placeholder="mobile number" />
                                        {/* <MaskedInput mask="0911 111 1111" name="card" size="13" /> */}
                                    </Form.Item>
                                    <Form.Item label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your preferred password.',
                                            },
                                        ]}>
                                        <Input placeholder="password" type="password" />
                                    </Form.Item>
                                    <Form.Item label="Confirm Password"
                                        name="confirm_password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please retype your password.',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                },
                                            }),
                                        ]}>
                                        <Input placeholder="confirm password" type="password" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24} lg={12}>
                                    <Divider orientation="left">Delivery Partner Details</Divider>
                                    <Form.Item label="Licence Number"
                                        name="license_number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your license number.',
                                            },
                                        ]}>
                                        <Input placeholder="license number" />
                                    </Form.Item>
                                    <Form.Item label="Licence Type"
                                        name="license_type"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your license number.',
                                            },
                                        ]}>
                                        <Select defaultValue="default">
                                            <Option value="default">select one</Option>
                                            <Option value="NP">Non-Professional</Option>
                                            <Option value="PR">Professional</Option>
                                            <Option value="SP">Student's Permit</Option>
                                        </Select>
                                    </Form.Item>
                                    <Form.Item label="Restrictions"
                                        name="license_restrictions"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your license restrictions.',
                                            },
                                        ]}>
                                        <Input placeholder="restrictions" />
                                    </Form.Item>
                                    <Form.Item {...tailFormLayout}>
                                        <Button type="primary" className="bm-btn w-100 mt-2" htmlType="submit">
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </div>
            </Content>
            <Footer className="bm-static-footer">
                RIDE EAT is a Trademark of Ride Eat Technologies Corporation. Copyright © { new Date().getFullYear() }.
            </Footer>
        </Layout>
    );
}
