
import React, { useEffect, useState } from 'react';
import { useParams, Link, useHistory } from 'react-router-dom';
import { Row, Col, Card, Form, Input, Button, Alert, Select, Table } from 'antd';
import { v4 as UUIDv4 } from 'uuid';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchants } from '../../../../services/merchants';
import { GetCategories, ImportCategories } from '../../../../services/categories';
import { GetMerchantItems, ImportItems } from '../../../../services/items';
import { GetItemsVariations, BatchPriceUpdate } from '../../../../services/itemsvariations';
import { GetAddOnGroups, ImportAddOnGroups } from '../../../../services/addongroups';
import { GetAddOns, BatchAddOnsUpdate } from '../../../../services/addons';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { NotificationManager } from 'react-notifications';
import { ConvertToDecimal } from '../../../../functions/Math';
import { Debounce } from '../../../../functions/Utilities';

export default function VariationPrices({currentUser}) {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchants, setMerchants] = useState(null);
    const [importData, setImportData] = useState(null);
    const [dataSetType, setDataSetType] = useState(null);
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const history = useHistory();
    const [form] = Form.useForm();
    
    const loadPageData = async() => {
        showLoader();
        const merchants = await GetMerchants(null);
        setMerchants(merchants.data.data);
        setDisplay(true);
        hideLoader();
    }

    const columnsItems = [
        {
            title: 'Item Name',
            render: (text, row) => row.item_name,
        },
        {
            title: 'Status',
            dataIndex: 'item_status',
            width: 120,
            render: text => GetStatusLabels('items', parseInt(text))
        }
    ];

    const columnsVariations = [
        {
            title: 'Variation Name',
            render: (text, row) => row.variation_name,
        },
        {
            title: 'Price',
            width: 120,
            align: 'right',
            render: (text, row) => <Input className="text-end item-variation" data-variation-uuid={row.variation_uuid} defaultValue={ConvertToDecimal(row.variation_price)} />
        },
        {
            title: 'Status',
            dataIndex: 'variation_status',
            width: 120,
            render: text => GetStatusLabels('variations', parseInt(text))
        }
    ];

    const columnsAddOnGroups = [
        {
            title: 'Add On Group Name',
            render: (text, row) => row.add_on_group_name,
        },
        {
            title: 'Status',
            dataIndex: 'add_on_group_status',
            width: 120,
            render: text => GetStatusLabels('add-on-groups', parseInt(text))
        }
    ];

    const columnsAddOns = [
        {
            title: 'Add On Name',
            render: (text, row) => row.add_on_name,
        },
        {
            title: 'Price',
            width: 120,
            align: 'right',
            render: (text, row) => <Input className="text-end item-add-on" data-add-on-uuid={row.add_on_uuid} defaultValue={ConvertToDecimal(row.add_on_price)} />
        },
        {
            title: 'Status',
            dataIndex: 'add_on_status',
            width: 120,
            render: text => GetStatusLabels('add-ons', parseInt(text))
        }
    ];

    const ItemsExpand = (record, index, indent, expanded) => {
        const variationsData = importData.variations.filter(function(obj) { return obj.item_uuid === record.item_uuid});
        return <Table rowKey="variation_uuid" columns={columnsVariations} dataSource={variationsData} size={'middle'} className="bm-table" pagination={false} />
    }

    const AddOnGroupsExpand = (record) => {
        const addOnsData = importData.addOns.filter(function(obj) { return obj.add_on_group_uuid === record.add_on_group_uuid});
        return <Table rowKey="add_on_uuid" columns={columnsAddOns} dataSource={addOnsData} size={'middle'} className="bm-table" pagination={false} />
    }

    const loadDataHandler = async(values) => {
        showLoader();
        const baseItemsResult = await GetMerchantItems(values.merchant_uuid);
        const baseVariationsResult = await getItemVariations(baseItemsResult.data.data);
        const baseAddOnGroupsResult = await GetAddOnGroups(values.merchant_uuid);
        const baseAddOnsResult = await getAddOnItems(baseAddOnGroupsResult.data.data);
        const tempItemsData = [];
        const tempVariationsData = [];
        const tempAddOnGroupsData = [];
        const tempAddOnsData = [];

        baseItemsResult.data.data.map((item, index) => {
            let tempItem = {
                item_name: item.item_name,
                item_photo_file_uuid: item.item_photo_file_uuid,
                item_status: 1,
                item_uuid: item.item_uuid,
                merchant_uuid: values.target_merchant_uuid
            }
            tempItemsData.push(tempItem);
            const resultVariation = baseVariationsResult.filter(function(obj) { return obj.item_uuid === item.item_uuid});
            resultVariation[0].variations.map(variationItem => {
                let tempVariation = {
                    is_bestseller: variationItem.is_bestseller,
                    item_uuid: item.item_uuid,
                    variation_description: variationItem.variation_description,
                    variation_name: variationItem.variation_name,
                    variation_price: variationItem.variation_price,
                    variation_status: 1,
                    variation_uuid: variationItem.variation_uuid
                }
                tempVariationsData.push(tempVariation);
            })
        });

        baseAddOnGroupsResult.data.data.map((item, index) => {
            let tempGroup = {
                add_on_group_uuid: item.add_on_group_uuid,
                merchant_uuid: values.target_merchant_uuid,
                add_on_group_name: item.add_on_group_name,
                is_required: item.is_required,
                is_multiple: item.is_multiple,
                add_on_group_status: 1
            }
            tempAddOnGroupsData.push(tempGroup);
            const resultAddOns = baseAddOnsResult.filter(function(obj) { return obj.add_on_group_uuid === item.add_on_group_uuid});
            resultAddOns[0].add_ons.map(addOnsItem => {
                let tempAddOn = {
                    add_on_uuid: addOnsItem.add_on_uuid,
                    add_on_group_uuid: item.add_on_group_uuid,
                    add_on_name: addOnsItem.add_on_name,
                    add_on_description: addOnsItem.add_on_description,
                    add_on_price: addOnsItem.add_on_price,
                    is_bestseller: addOnsItem.is_bestseller,
                    add_on_status: 1
                }
                tempAddOnsData.push(tempAddOn);
            })
        });
        setImportData({
            items: tempItemsData,
            variations: tempVariationsData,
            addOnGroups: tempAddOnGroupsData,
            addOns: tempAddOnsData
        });
        hideLoader();
    };

    const getItemVariations = (items) => {
        const promisesVariations = items.map(async(item) => {
            const resultVariation = await GetItemsVariations(item.item_uuid);
            return {
                item_uuid: item.item_uuid,
                variations: resultVariation.data.data
            }
        });
        return Promise.all(promisesVariations);
    }

    const getAddOnItems  = (groups) => {
        const promisesAddOns = groups.map(async(item) => {
            const resultAddOns = await GetAddOns(item.add_on_group_uuid);
            return {
                add_on_group_uuid: item.add_on_group_uuid,
                add_ons: resultAddOns.data.data
            }
        });
        return Promise.all(promisesAddOns);
    }

    const handlePriceChanges = async() => {
        showLoader();
        let dataItems = document.getElementsByClassName('item-variation');
        let dataAddOns = document.getElementsByClassName('item-add-on');

        var dataItemsFormatted = [];
        var dataAddOnsFormatted = [];

        for (let item of dataItems) {
            dataItemsFormatted.push({
                variation_uuid: item.dataset.variationUuid,
                variation_price: item.value * 100
            });
        }

        for (let item of dataAddOns) {
            dataAddOnsFormatted.push({
                add_on_uuid: item.dataset.addOnUuid,
                add_on_price: item.value * 100
            });
        }

        const variationsData = {
            data: dataItemsFormatted
        }
        const addOnsData = {
            data: dataAddOnsFormatted
        }

        Promise.all([
            BatchPriceUpdate(variationsData),
            BatchAddOnsUpdate(addOnsData)
        ]).then(result => {
            Debounce(NotificationManager.success('Batch prices updated.', 'Success', 2000), 2000);
            setSuccess(true);
            setImportData(null);
            form.resetFields();
            hideLoader();
        }).catch(e => {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        });
    }

    useEffect(() => {
        loadPageData();
        return function cleanup() {
            loadPageData();
        }
        // eslint-disable-next-line
    }, []);

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='merchant-options'>
            { loader }
            {
                display &&
                <Row gutter={24}>
                    <Col xs={24} lg={{span: 18, offset: 2}} xl={{span: 12, offset: 6}}>
                        <Card title="Variation Prices" className="bm-card mb-5">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Variation Prices Updated"
                                    description="Prices has been updated successfully."
                                    type="success"
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={loadDataHandler} form={form}>
                                <Form.Item label="Merchant"
                                    name="merchant_uuid"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a merchant.',
                                        },
                                    ]}>
                                    <Select
                                        showSearch={true}
                                        defaultValue=""
                                        className="bm-select"
                                        allowClear={true}
                                        onChange={(event) => setImportData(null)}
                                        filterOption={(input, option) => 
                                            option.props.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }>
                                        <Option value="">select one</Option>
                                        {
                                            merchants &&
                                            merchants.map(item => {
                                                return <Option value={item.merchant_uuid}>{item.merchant_name} ({item.company_name})</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Load Data
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
            {
                importData &&
                <div>
                    <h3 className="mb-4"></h3>
                    <div className="d-flex w-100">
                        <h3 className="mb-4">Data to Import</h3>
                        <div className="ms-auto buttons">
                            <Button type="primary" className="bm-btn" onClick={() => handlePriceChanges()}>Submit Price Changes</Button>
                        </div>
                    </div>
                    <div>
                        <Table rowKey="item_uuid" dataSource={importData.items} columns={columnsItems} expandable={{expandedRowRender: ItemsExpand, defaultExpandAllRows: true}} size={'middle'} className="bm-table mb-4" pagination={false} />
                        <Table rowKey="add_on_group_uuid" dataSource={importData.addOnGroups} columns={columnsAddOnGroups} expandable={{expandedRowRender: AddOnGroupsExpand, defaultExpandAllRows: true}} size={'middle'} className="bm-table" pagination={false} />
                    </div>
                </div>
            }
        </PageLayout>
    )
}
