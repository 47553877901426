import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, InputNumber, Select, Switch, Button, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { UpdateAddOn } from '../../../../services/addons';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function AddOnsUpdate({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const { TextArea } = Input;
    const history = useHistory();
    const { Option } = Select;
    const addOnGroupDetails = history.location.state.addOnGroupDetails;
    const addOnDetails = history.location.state.addOnDetails;

    const submitHandler = async (values) => {
        showLoader();
        try {
            // textarea undefined when user skips field
            if (values.add_on_description === undefined) {
                values.add_on_description = '';
            }
            (values.is_bestseller === true) ? values.is_bestseller = 1: values.is_bestseller = 0;
            const formattedValues = {
                ...values,
                add_on_group_uuid: addOnGroupDetails.add_on_group_uuid,
                add_on_uuid: addOnDetails.add_on_uuid,
                add_on_price: values.add_on_price * 100,
                add_on_status: parseInt(values.add_on_status)
            }
            const result = await UpdateAddOn(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Add-on has been updated.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Add-on already exists. Please use a different add-on name.', 'Add-On Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='add-ons'>
            { loader }
            {
                <Row>
                    <Col xs={24} lg={{span: 18, offset: 2}} xl={{span: 12, offset: 6}}>
                        <Card title="Update Add-On" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="Add-On Updated"
                                    description="Add-on has been updated successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.goBack()}>Back</Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}
                                initialValues={{
                                    'add_on_name': addOnDetails.add_on_name,
                                    'add_on_description': addOnDetails.add_on_description,
                                    'add_on_price': addOnDetails.add_on_price / 100,
                                    'add_on_status': String(addOnDetails.add_on_status)
                                }}>
                                <Form.Item label="Add-On Name"
                                    name="add_on_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the add-on name.',
                                        },
                                    ]}>
                                    <Input placeholder="variation name" />
                                </Form.Item>
                                <Form.Item label="Add-On Description"
                                    name="add_on_description">
                                    <TextArea rows={4} placeholder="add-on description" />
                                </Form.Item>
                                <Form.Item label="Price"
                                    name="add_on_price"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the add-on price.',
                                        },
                                    ]}>
                                    <InputNumber placeholder="0.00" precision={2} step={.01} />
                                </Form.Item>
                                <Form.Item label="Best Seller"
                                    name="is_bestseller">
                                    <Switch defaultChecked={(addOnDetails.is_bestseller === 1) ? true : false} />
                                </Form.Item>
                                <Form.Item label="Status"
                                    name="add_on_status"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select status.',
                                        },
                                    ]}>
                                    <Select>
                                        <Option value="1">Active</Option>
                                        <Option value="0">Inactive</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
