import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetLatestOrders } from '../../../../services/orders';
import usePageLoader from '../../../../hooks/usePageLoader';
import { ConvertToDecimal } from '../../../../functions/Math';
import { GetStatusLabels } from '../../../../functions/Labels';
import { DisplayDateTime } from '../../../../functions/Utilities';

export default function OrdersLatest({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [latestOrders, setLatestOrders] = useState(null);
    
    const getLatest = async() => {
        showLoader();
        const result = await GetLatestOrders(currentUser.other_details.service_area_uuid, 100);
        setLatestOrders(result.data.data);
        hideLoader();
    }

    const columns = [
        {
            title: 'Area',
            render: (text, row) => row.service_area_name,
        },
        {
            title: 'Tracking No',
            dataIndex: 'order_tracking_no',
            render: (text, row) => <Link className="text-link-primary-x3" to={{ pathname: `/app/admin/orders/view/${row.order_uuid}`, state: {orderDetails: row}}}>{text}</Link>,
        },
        {
            title: 'Order Amount',
            dataIndex: 'order_total_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_total_amount),
        },
        {
            title: 'Merchant Due',
            dataIndex: 'order_merchant_due_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_total_amount - row.order_admin_due_amount - row.order_delivery_due_amount),
        },
        {
            title: 'Delivery Fee',
            dataIndex: 'order_delivery_due_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_delivery_due_amount),
        },
        {
            title: 'Platform',
            dataIndex: 'order_admin_due_amount',
            align: 'right',
            render: (text, row) => 'P' + ConvertToDecimal(row.order_admin_due_amount),
        },
        {
            title: 'Voucher',
            dataIndex: 'voucher_uuid',
            render: (text, row) => row.voucher_uuid === null ? '-' : <Link className="text-link-primary-x3" to={{ pathname: `/app/admin/vouchers/view/${row.voucher_uuid}`}}>{row.voucher_code}</Link>,
        },
        {
            title: 'Free Delivery',
            render: (text, row) => GetStatusLabels('bool', row.charge_is_free_delivery),
        },
        {
            title: 'Date & Time',
            render: (text, row) => <span>{DisplayDateTime(row.created_at)}</span>,
        },
        {
            title: 'Status',
            dataIndex: 'order_status',
            width: 120,
            render: text => GetStatusLabels('orders', parseInt(text))
        }
    ];

    useEffect(() => {
        getLatest();
        return function cleanup() {
            getLatest();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='orders'>
            { loader }
            <h3 className="mb-4">Latest Orders</h3>
            {
                latestOrders &&
                <Table rowKey="order_uuid" dataSource={latestOrders} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
