import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetServiceAreas } from '../../../../services/serviceareas';
import { AddPortalPersonnel } from '../../../../services/users';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function AdminAdd({currentUser}) {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [serviceAreas, setServiceAreas] = useState(null);
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const history = useHistory();

    const getServiceAreas = async() => {
        showLoader();
        const serviceAreasResult = await GetServiceAreas();
        setServiceAreas(serviceAreasResult.data.data);
        setDisplay(true);
        hideLoader();
    }
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            const formattedValues = {
                ...values,
                service_area_uuid: (typeof values.service_area_uuid === 'undefined' || values.service_area_uuid === '') ? null : values.service_area_uuid,
                mobile_number: '63' + values.mobile_number.substring(1),
                user_type_code: 'A001',
                user_status: 1
            }

            const result = await AddPortalPersonnel(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Admin User information has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Admin User email address already exists. Please use a different email.', 'Email Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 16 } }
    }

    useEffect(() => {
        getServiceAreas();
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='administrators'>
            { loader }
            {
                display && 
                <Row>
                    <Col xs={24} lg={{span: 18, offset: 2}} xl={{span: 12, offset: 6}}>
                        <Card title="Add Admin Users" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="New Admin User Added"
                                    description="Admin User has been added successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/admin/administrators/list')}>
                                            Back to List
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Service Area"
                                    name="service_area_uuid"
                                    rules={[
                                        {
                                            required: false,
                                            message: 'Please select a service area.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select" allowClear={true}>
                                        <Option value="">select one</Option>
                                        {
                                            serviceAreas &&
                                            serviceAreas.map(item => {
                                                return <Option value={item.service_area_uuid}>{item.service_area_name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item label="First Name"
                                    name="first_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your first name.',
                                        },
                                    ]}>
                                    <Input placeholder="first name" />
                                </Form.Item>
                                <Form.Item label="Last Name"
                                    name="last_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your last name.',
                                        },
                                    ]}>
                                    <Input placeholder="last name" />
                                </Form.Item>
                                <Form.Item label="Mobile Number"
                                    name="mobile_number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter a valid mobile number.',
                                        },
                                    ]}
                                    >
                                    <Input placeholder="09" />
                                </Form.Item>
                                <Form.Item label="Email"
                                    name="email_address"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your email address.',
                                        },
                                    ]}>
                                    <Input placeholder="email address" />
                                </Form.Item>
                                <Form.Item label="Password"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter your preferred password.',
                                        },
                                    ]}>
                                    <Input placeholder="password" type="password" />
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
