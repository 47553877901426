import React, { useState } from 'react';
import { Row, Col, Card, Form, Input, Button, Alert, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { AddCategories } from '../../../../services/categories';
import { CategoryTypes } from '../../../../data/CategoryTypes';
import usePageLoader from '../../../../hooks/usePageLoader';
import { NotificationManager } from 'react-notifications';
import { Debounce } from '../../../../functions/Utilities';

export default function CategoriesAdd({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [success, setSuccess] = useState(null);
    const { Option } = Select;
    const { TextArea } = Input;
    const history = useHistory();
    
    const submitHandler = async (values) => {
        showLoader();
        try {
            // textarea undefined when user skips field
            if (values.category_description === undefined) {
                values.category_description = "";
            }
            const formattedValues = {
                ...values,
                merchant_uuid: currentUser.other_details.merchant_uuid,
                category_status: 1
            }
            const result = await AddCategories(formattedValues);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Category has been saved.', 'Success', 2000), 2000);
                    setSuccess(true);
                    break;
                case 'error':
                    Debounce(NotificationManager.error('Category already exists. Please use a category name.', 'Category Exists', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('Something went wrong. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 }
    };

    const tailFormLayout = {
        wrapperCol: { xs: { span: 24 }, sm: { offset: 8, span: 16 } }
    }

    return (
        <PageLayout activeLink='categories'>
            { loader }
            {
                <Row>
                    <Col xs={24} lg={{span: 18, offset: 2}} xl={{span: 12, offset: 6}}>
                        <Card title="Add Category" className="bm-card">
                            {
                                success &&
                                <Alert
                                    className="bm-alert mt-1 mb-4"
                                    message="New Category Added"
                                    description="Category has been added successfully."
                                    type="success"
                                    action={
                                        <Button type="white" className="bm-btn" onClick={() => history.push('/app/merchants/categories/list')}>
                                            Back to List
                                        </Button>
                                    }
                                />
                            }
                            <Form layout={"horizontal"} {...formLayout} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="Category Name"
                                    name="category_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter the category name.',
                                        },
                                    ]}>
                                    <Input placeholder="category name" />
                                </Form.Item>
                                <Form.Item label="Description"
                                    name="category_description">
                                    <TextArea rows={4} placeholder="description" />
                                </Form.Item>
                                <Form.Item label="Category Type"
                                    name="category_type_code"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select a branch.',
                                        },
                                    ]}>
                                    <Select defaultValue="" className="bm-select">
                                        <Option value="">select one</Option>
                                        {
                                            CategoryTypes.map(item => {
                                                return <Option value={item.code}>{item.categoryType}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item {...tailFormLayout}>
                                    <Button type="primary" className="bm-btn w-100" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            }
        </PageLayout>
    )
}
