import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {
    Landing,
    Login,
    Logout,
    RegisterMerchant,
    RegisterDelivery,
    ForgotPassword,

    AdminDashboard,
    TrendsOrders,
    TrendsRegistrations,
    ActiveUsersMap,
    ServiceAreasList,
    ServiceAreasAdd,
    ServiceAreasView,
    ServiceAreasUpdate,
    FeaturesAdd,
    FeaturesView,
    FeaturesUpdate,
    SpotlightsAdd,
    SpotlightsView,
    SpotlightsUpdate,
    MerchantsList,
    MerchantsView,
    MerchantsUpdate,
    MerchantsBranchView,
    MerchantPartnersList,
    MerchantPartnersView,
    OptionsMain,
    VariationPrices,
    ImportsAdd,
    DeliveryPartnersList,
    DeliveryPartnersView,
    DeliveryPartnersUpdate,
    CustomersList,
    CustomersView,
    VouchersList,
    VouchersAdd,
    VouchersView,
    VouchersUpdate,
    RequestsList,
    RequestsView,
    RequestUpdate,
    OrdersLatest,
    OrdersLatestView,
    AddTransactions,
    AdminList,
    AdminAdd,
    AdminView,
    SmsSend,

    MerchantDashboard,
    BranchesList,
    BranchesAdd,
    BranchesView,
    BranchesUpdate,
    UsersList,
    UsersView,
    UsersAdd,
    UsersUpdate,
    CategoriesList,
    CategoriesAdd,
    CategoriesView,
    CategoriesUpdate,
    BranchCategoriesList,
    BranchCategoriesView,
    MenusList,
    MenusAdd,
    MenusView,
    MenusUpdate,
    ItemsList,
    ItemsAdd,
    ItemsUpdate,
    ItemsView,
    VariationsAdd,
    VariationsView,
    VariationsUpdate,
    AddOnsList,
    GroupsAdd,
    AddOnsAdd,
    GroupsView,
    GroupsUpdate,
    AddOnsView,
    AddOnsUpdate,
    MenusConfigure,
    OrdersList,
    OrdersView,
    OrdersPast,

    ProfileView,
    ProfileUpdate,
    ProfilePassword
} from "./pages";
import {AuthProvider} from "./contexts/AuthContext";
import PrivateRoute from "./PrivateRoute";

function App() {
    return (
        <Router>
            <AuthProvider>
                <Switch>
                    <Route exact path="/" component={Landing}></Route>
                    <Route path="/login" component={Login}></Route>
                    <Route path="/logout" component={Logout}></Route>
                    <Route path="/register/merchant" component={RegisterMerchant}></Route>
                    <Route path="/register/delivery" component={RegisterDelivery}></Route>
                    <Route path="/forgot-password/:uuid" component={ForgotPassword}></Route>
                    <PrivateRoute path="/app/admin/dashboard" component={AdminDashboard}></PrivateRoute>
                    <PrivateRoute path="/app/admin/trends-orders" component={TrendsOrders}></PrivateRoute>
                    <PrivateRoute path="/app/admin/trends-registrations" component={TrendsRegistrations}></PrivateRoute>
                    <PrivateRoute path="/app/admin/active-users-map" component={ActiveUsersMap}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/list" component={ServiceAreasList}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/add" component={ServiceAreasAdd}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/view/:uuid" component={ServiceAreasView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/update/:uuid" component={ServiceAreasUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/features/add/:uuid" component={FeaturesAdd}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/features/view/:uuid" component={FeaturesView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/features/update/:uuid" component={FeaturesUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/spotlights/add/:uuid" component={SpotlightsAdd}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/spotlights/view/:uuid" component={SpotlightsView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/service-areas/spotlights/update/:uuid" component={SpotlightsUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/admin/merchants/list" component={MerchantsList}></PrivateRoute>
                    <PrivateRoute path="/app/admin/merchants/view/:uuid" component={MerchantsView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/merchants/update/:uuid" component={MerchantsUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/admin/merchants/branches/view/:uuid" component={MerchantsBranchView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/merchant-partners/list"  component={MerchantPartnersList}></PrivateRoute>
                    <PrivateRoute path="/app/admin/merchant-partners/view/:uuid" component={MerchantPartnersView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/merchant-options/main" component={OptionsMain}></PrivateRoute>
                    <PrivateRoute path="/app/admin/merchant-options/variation-prices" component={VariationPrices}></PrivateRoute>
                    <PrivateRoute path="/app/admin/imports/add" component={ImportsAdd}></PrivateRoute>
                    <PrivateRoute path="/app/admin/delivery-partners/list" component={DeliveryPartnersList}></PrivateRoute>
                    <PrivateRoute path="/app/admin/delivery-partners/view/:uuid" component={DeliveryPartnersView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/delivery-partners/update/:uuid" component={DeliveryPartnersUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/admin/customers/list"  component={CustomersList}></PrivateRoute>
                    <PrivateRoute path="/app/admin/customers/view/:uuid"  component={CustomersView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/vouchers/list" component={VouchersList}></PrivateRoute>
                    <PrivateRoute path="/app/admin/vouchers/add" component={VouchersAdd}></PrivateRoute>
                    <PrivateRoute path="/app/admin/vouchers/view/:uuid" component={VouchersView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/vouchers/update/:uuid" component={VouchersUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/admin/requests/list" component={RequestsList}></PrivateRoute>
                    <PrivateRoute path="/app/admin/requests/view/:uuid" component={RequestsView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/requests/update/:uuid" component={RequestUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/admin/orders/latest" component={OrdersLatest}></PrivateRoute>
                    <PrivateRoute path="/app/admin/orders/view" component={OrdersLatestView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/wallets/add/:uuid" component={AddTransactions}></PrivateRoute>
                    <PrivateRoute path="/app/admin/administrators/list" component={AdminList}></PrivateRoute>
                    <PrivateRoute path="/app/admin/administrators/add" component={AdminAdd}></PrivateRoute>
                    <PrivateRoute path="/app/admin/administrators/view/:uuid" component={AdminView}></PrivateRoute>
                    <PrivateRoute path="/app/admin/sms/send" component={SmsSend}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/dashboard" component={MerchantDashboard}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/branches/list" component={BranchesList}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/branches/add" component={BranchesAdd}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/branches/view/:uuid" component={BranchesView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/branches/update/:uuid" component={BranchesUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/users/list" component={UsersList}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/users/view/:uuid" component={UsersView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/users/add" component={UsersAdd}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/users/update/:uuid" component={UsersUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/categories/list" component={CategoriesList}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/categories/add" component={CategoriesAdd}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/categories/view/:uuid" component={CategoriesView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/categories/update/:uuid" component={CategoriesUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/branch-categories/list" component={BranchCategoriesList}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/branch-categories/view/:uuid" component={BranchCategoriesView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/menus/list" component={MenusList}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/menus/add" component={MenusAdd}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/menus/view/:uuid" component={MenusView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/menus/update/:uuid" component={MenusUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/menus/configure" component={MenusConfigure}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/items/list" component={ItemsList}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/items/view/:uuid" component={ItemsView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/items/add" component={ItemsAdd}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/items/update/:uuid" component={ItemsUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/items/variations/add/:uuid" component={VariationsAdd}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/items/variations/view/:uuid" component={VariationsView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/items/variations/update/:uuid" component={VariationsUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/add-ons/list" component={AddOnsList}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/add-ons/add/:uuid" component={AddOnsAdd}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/add-ons/view/:uuid" component={AddOnsView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/add-ons/update/:uuid" component={AddOnsUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/add-ons/groups/add" component={GroupsAdd}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/add-ons/groups/view/:uuid" component={GroupsView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/add-ons/groups/update/:uuid" component={GroupsUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/orders/list" component={OrdersList}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/orders/view" component={OrdersView}></PrivateRoute>
                    <PrivateRoute path="/app/merchants/orders/past" component={OrdersPast}></PrivateRoute>
                    <PrivateRoute path="/app/profile/view" component={ProfileView}></PrivateRoute>
                    <PrivateRoute path="/app/profile/update" component={ProfileUpdate}></PrivateRoute>
                    <PrivateRoute path="/app/profile/password" component={ProfilePassword}></PrivateRoute>
                </Switch>
            </AuthProvider>
        </Router>
    );
}

export default App;
