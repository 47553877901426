import axios from 'axios';

export const GetOrderItems = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/order-items/order/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}

export const GetOrderItemsActive = async(uuid) => {
    try {
        const result = await axios.get(process.env.REACT_APP_API_BASE_URL + '/order-items/active/order/' + uuid);
        return result;
    } catch (error) {
        throw error;
    }
}
