import React, { useEffect, useState } from 'react';
import { Layout, Card, Form, Input, Button, Alert } from 'antd';
import Logo from '../../assets/img/rideeat-logo-dark.png';
import { useParams } from 'react-router-dom';
import usePageLoader from '../../hooks/usePageLoader';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Debounce } from '../../functions/Utilities';
import { GetUserDetails, UpdateUserDetails } from '../../services/users';

export default function ForgotPassword() {
    const [loader, showLoader, hideLoader] = usePageLoader();
    const { Content, Footer } = Layout;
    const { uuid }  = useParams();
    const [userDetails, setUserDetails] = useState(null);

    const getPageData = async() => {
        const userResult = await GetUserDetails(uuid);
        if (userResult.data.data) {
            setUserDetails(userResult.data.data);
        } else {
            setUserDetails('expired');
        }
    }

    useEffect(() => {
        getPageData();
        return () => {
            getPageData();
        }
    }, []);

    const submitHandler = async(values) => {
        const postData = {
            ...values,
            user_uuid: uuid
        }
        showLoader();
        try {
            const result = await UpdateUserDetails(postData);
            switch(result.data.status) {
                case 'success':
                    Debounce(NotificationManager.success('Password updated successfully.', 'Success', 2000), 2000);
                    break;
                default:
                    Debounce(NotificationManager.error('There has been an error. Please try again.', 'Sorry', 2000), 2000);
                    break;
            }
            hideLoader();
        } catch (e) {
            Debounce(NotificationManager.warning('There has been an error. Please try again.', 'Sorry', 2000), 2000);
            console.log(e.message)
            hideLoader();
        }
    };

    return (
        <Layout className="bm-layout bm-static centered">
            { loader }
            <NotificationContainer/>
            <Content>
                <div className="bm-auth-container">
                    <img src={Logo} className="bm-static-logo" alt="ride eat" />
                    <Card title="Change Password" className="bm-card" bordered={false}>
                        {
                            (userDetails === 'expired') &&
                            <Alert
                                className="bm-alert mt-1"
                                message="Expired"
                                description="The link you provided is no longer valid."
                                type="danger"
                            />
                        }
                        {
                            userDetails &&
                            <Form layout={"vertical"} className="bm-form" onFinish={submitHandler}>
                                <Form.Item label="New Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter your preferred password.',
                                            },
                                        ]}>
                                        <Input placeholder="password" type="password" />
                                    </Form.Item>
                                    <Form.Item label="Confirm New Password"
                                        name="confirm_password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please retype your password.',
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue('password') === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                                },
                                            }),
                                        ]}>
                                        <Input placeholder="confirm password" type="password" />
                                    </Form.Item>
                                <Form.Item>
                                    <Button type="primary" className="bm-btn w-100 mt-2" htmlType="submit">
                                        Submit
                                    </Button>
                                </Form.Item>
                            </Form>
                        }
                    </Card>
                </div>
            </Content>
            <Footer className="bm-static-footer">
                RIDE EAT is a Trademark of Ride Eat Technologies Corporation. Copyright © { new Date().getFullYear() }.
            </Footer>
        </Layout>
    );
}
