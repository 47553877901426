import React, { useState, useEffect } from 'react';
import { Layout, Menu } from 'antd';
import { useHistory } from 'react-router-dom';
import Logo from '../../assets/img/rideeat-logo-light.png';
import Briefcase from '../icons/Briefcase';
import Layers from '../icons/Layers';
import Presentation from '../icons/Presentation';
import Truck from '../icons/Truck';
import Profile from '../icons/Profile';
import Location from '../icons/Location';
import Gantt from '../icons/Gantt';
import PaperPen from '../icons/PaperPen';
import MenuBoard from '../icons/Menu';
import Burger from '../icons/Burger';
import Voucher from '../icons/Voucher';
import Bag from '../icons/Bag';
import Cheese from '../icons/Cheese';
import Lock from '../icons/Lock';
import Sms from '../icons/Sms';
import List from '../icons/List';

export default function PageSider({currentUser, activeLink, sideCollapsed}) {
    const { Sider } = Layout;
    const history = useHistory();
    const [adminNav, setAdminNav] = useState(null);
    const [merchantNav, setMerchantNav] = useState(null);
    
    useEffect(() => {
        switch(currentUser.user_type_code) {
            case 'A001':
                setAdminNav(true);
                break;
            case 'A004':
                setMerchantNav(true);
                break;
            default:
                break;
        }
    }, [currentUser.user_type_code]);

    return (
        <Sider trigger={null} collapsible collapsed={sideCollapsed}>
            <div className="brand-wrapper">
                <img src={Logo} className="brand" alt="ride eat" />
            </div>
            {
                adminNav && currentUser.other_details.service_area_uuid === null &&
                <Menu defaultSelectedKeys={[activeLink]} mode="inline">
                    <Menu.Item key="dashboard" icon={<Layers />} onClick={() => history.push('/app/admin/dashboard')}>Dashboard</Menu.Item>
                    <Menu.Item key="orders" icon={<Truck />} onClick={() => history.push('/app/admin/orders/latest')}>Orders</Menu.Item>
                    <Menu.Item key="service-areas" icon={<Location />} onClick={() => history.push('/app/admin/service-areas/list')}>Service Areas</Menu.Item>
                    <Menu.Item key="merchants" icon={<Briefcase />} onClick={() => history.push('/app/admin/merchants/list')}>Merchants</Menu.Item>
                    <Menu.Item key="merchant-partners" icon={<Presentation />} onClick={() => history.push('/app/admin/merchant-partners/list')}>Merchant Partners</Menu.Item>
                    <Menu.Item key="merchant-options" icon={<List />} onClick={() => history.push('/app/admin/merchant-options/main')}>Merchant Options</Menu.Item>
                    <Menu.Item key="delivery-partners" icon={<Truck />} onClick={() => history.push('/app/admin/delivery-partners/list')}>Delivery Partners</Menu.Item>
                    <Menu.Item key="customers" icon={<Profile />} onClick={() => history.push('/app/admin/customers/list')}>Customers</Menu.Item>
                    <Menu.Item key="vouchers" icon={<Voucher />} onClick={() => history.push('/app/admin/vouchers/list')}>Vouchers</Menu.Item>
                    <Menu.Item key="requests" icon={<PaperPen />} onClick={() => history.push('/app/admin/requests/list')}>Requests</Menu.Item>
                    {/* <Menu.Item key="sms" icon={<Sms />} onClick={() => history.push('/app/admin/sms/send')}>Short Messaging</Menu.Item>  */}
                    <Menu.Item key="administrators" icon={<Lock />} onClick={() => history.push('/app/admin/administrators/list')}>Administrators</Menu.Item>
                </Menu>
            }
            {
                adminNav && currentUser.other_details.service_area_uuid !== null &&
                <Menu defaultSelectedKeys={[activeLink]} mode="inline">
                    <Menu.Item key="dashboard" icon={<Layers />} onClick={() => history.push('/app/admin/dashboard')}>Dashboard</Menu.Item>
                    <Menu.Item key="orders" icon={<Truck />} onClick={() => history.push('/app/admin/orders/latest')}>Orders</Menu.Item>
                    <Menu.Item key="merchants" icon={<Briefcase />} onClick={() => history.push('/app/admin/merchants/list')}>Merchants</Menu.Item>
                    <Menu.Item key="merchant-partners" icon={<Presentation />} onClick={() => history.push('/app/admin/merchant-partners/list')}>Merchant Partners</Menu.Item>
                    <Menu.Item key="imports" icon={<List />} onClick={() => history.push('/app/admin/imports/add')}>Imports</Menu.Item>
                    <Menu.Item key="delivery-partners" icon={<Truck />} onClick={() => history.push('/app/admin/delivery-partners/list')}>Delivery Partners</Menu.Item>
                    <Menu.Item key="customers" icon={<Profile />} onClick={() => history.push('/app/admin/customers/list')}>Customers</Menu.Item>
                    <Menu.Item key="requests" icon={<PaperPen />} onClick={() => history.push('/app/admin/requests/list')}>Requests</Menu.Item>
                </Menu>
            }
            {
                merchantNav && 
                <Menu defaultSelectedKeys={[activeLink]} mode="inline">
                    <Menu.Item key="dashboard" icon={<Layers />} onClick={() => history.push('/app/merchants/dashboard')}>Dashboard</Menu.Item>
                    <Menu.Item key="orders" icon={<Bag />} onClick={() => history.push('/app/merchants/orders/list')}>Orders</Menu.Item>
                    <Menu.Item key="branches" icon={<Location />} onClick={() => history.push('/app/merchants/branches/list')}>Branches</Menu.Item>
                    <Menu.Item key="users" icon={<Profile />} onClick={() => history.push('/app/merchants/users/list')}>Users</Menu.Item>
                    <Menu.Item key="categories" icon={<Gantt />} onClick={() => history.push('/app/merchants/categories/list')}>Categories</Menu.Item>
                    <Menu.Item key="branch-categories" icon={<PaperPen />} onClick={() => history.push('/app/merchants/branch-categories/list')}>Branch Categories</Menu.Item>
                    <Menu.Item key="menus" icon={<MenuBoard />} onClick={() => history.push('/app/merchants/menus/list')}>Menus</Menu.Item>
                    <Menu.Item key="items" icon={<Burger />} onClick={() => history.push('/app/merchants/items/list')}>Items</Menu.Item>
                    <Menu.Item key="add-ons" icon={<Cheese />} onClick={() => history.push('/app/merchants/add-ons/list')}>Add-Ons</Menu.Item>
                </Menu>
            }
        </Sider>
    )
}
