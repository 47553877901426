import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Table, Row, Col, Card, Button, Image } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetMerchantDetails, GetMerchantBranches } from '../../../../services/merchants';
import { GetFile } from '../../../../services/files';
import DefaultLogo from '../../../../assets/img/default-brand-logo.png';
import { ConvertToDecimal } from '../../../../functions/Math';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';

export default function MerchantsView({currentUser}) {
    const [display, setDisplay] = useState(null);
    const [loader, showLoader, hideLoader] = usePageLoader();
    const [merchantDetails, setMerchantDetails] = useState(null);
    const [merchantLogoDetails, setMerchantLogoDetails] = useState(null);
    const [merchantLogoUrl, setMerchantLogoUrl] = useState(DefaultLogo);
    const [branches, setBranches] = useState(null);
    const { uuid }  = useParams();
    const history = useHistory();
    
    const getMerchantDetails = async() => {
        showLoader();
        // get merchant details
        const merchantDetailsResult = await GetMerchantDetails(uuid);
        setMerchantDetails(merchantDetailsResult.data.data);

        if (merchantDetailsResult.data.data.merchant_logo_uuid !== '') {
            // get merchant logo
            const merchantLogoResult = await GetFile(merchantDetailsResult.data.data.merchant_logo_uuid);
            setMerchantLogoDetails(merchantLogoResult.data.data);
            setMerchantLogoUrl(merchantLogoResult.data.data.file_url);
        }
        
        // get branches list
        const merchantBranchesResult = await GetMerchantBranches(uuid, currentUser.other_details.service_area_uuid);
        setBranches(merchantBranchesResult.data.data);

        setDisplay(true);
        hideLoader();
    }

    const columns = [
        {
            title: 'Service Area',
            dataIndex: 'service_area_uuid',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/service-areas/view/${row.service_area_uuid}`}>{row.service_area_name}</Link>,
        },
        {
            title: 'Branch Name',
            dataIndex: 'branch_name',
            render: (text, row) => <Link className="text-link-primary-x3" to={`/app/admin/merchants/branches/view/${row.branch_uuid}`}>{text}</Link>,
        },
        {
            title: 'Branch Code',
            dataIndex: 'branch_code',
        },
        {
            title: 'Contact Person',
            dataIndex: 'contact_person',
        },
        {
            title: 'Tel Number',
            dataIndex: 'tel_number',
        },
        {
            title: 'Mobile Number',
            dataIndex: 'mobile_number',
        },
        {
            title: 'Email',
            dataIndex: 'email_address',
            render: text => <a className="text-link-primary-x3" href={`mailto:${text}`}>{text}</a>,
        },
    ];

    useEffect(() => {
        getMerchantDetails();
        return function cleanup() {
            getMerchantDetails();
        }
        // eslint-disable-next-line
    }, []);

    return (
        <PageLayout activeLink='merchants'>
            { loader }
            {
                display && 
                <Row gutter={24}>
                    <Col xs={24} md={16}>
                        <Card title="Stats" className="bm-card mb-4 mb-md-0">
                            Stats soon
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card title="Merchant Details" className="bm-card mb-4 mb-md-0"
                            actions={[
                                <div className="text-end"><Button type="outline-primary" className="bm-btn" onClick={() => history.push({pathname: '/app/admin/merchants/update/' + uuid, state: {merchantDetails, merchantLogoDetails}})}>Update</Button></div>
                            ]}>
                            <div className="bm-static-container">
                                <Image width={120} src={merchantLogoUrl} className="bm-image-rounded" />
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Company Name</div>
                                <div className="display">{merchantDetails.company_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Merchant Name</div>
                                <div className="display">{merchantDetails.merchant_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Contact Person</div>
                                <div className="display">{merchantDetails.contact_person}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Mobile Number</div>
                                <div className="display">{merchantDetails.mobile_number}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Email</div>
                                <div className="display"><a href={`mailto:${merchantDetails.email_address}`} className="text-link-primary-x3">{merchantDetails.email_address}</a></div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Compliance Code</div>
                                <div className="display">{merchantDetails.compliance_code}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Commission Rate</div>
                                <div className="display">{merchantDetails.commission_rate}%</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Price Rate</div>
                                <div className="display">{merchantDetails.price_rate}%</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Service Area</div>
                                <div className="display">{merchantDetails.service_area_name}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Free Delivery</div>
                                <div className="display">{GetStatusLabels('bool', merchantDetails.is_free_delivery)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Min of Free Delivery</div>
                                <div className="display">{'P' + ConvertToDecimal(merchantDetails.min_free_delivery)}</div>
                            </div>
                            <div className="bm-static-container">
                                <div className="title">Status</div>
                                <div className="display">{GetStatusLabels('merchants', merchantDetails.merchant_status)}</div>
                            </div>
                        </Card>
                    </Col>
                </Row>
            }

            <h3 className="mb-4">Branch List</h3>
            {
                branches &&
                <Table rowKey="branch_uuid" dataSource={branches} columns={columns} size={'middle'} className="bm-table" />
            }
        </PageLayout>
    )
}
