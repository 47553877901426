import moment from "moment";

export const Debounce = (callback, delay = 250) => {
	let timeoutId
    return (...args) => {
            clearTimeout(timeoutId)
            timeoutId = setTimeout(() => {
            timeoutId = null
            callback(...args)
        }, delay)
    }
}

export const FilterObject = (array, value, key) => {
    return array.filter(key ? a => a[key] === value : a => Object.keys(a).some(k => a[k] === value));
}

export const DisplayDateTime = (time) => {
    return moment(time).local().format('lll');
}

export const FormatMobileNumber = (mobileNumber) => {
    return '63' + mobileNumber.substr(mobileNumber.length - 10);
}