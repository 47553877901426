import React, { useEffect, useState } from 'react';
import { Table, Input, Card, List } from 'antd';
import { Link } from 'react-router-dom';
import PageLayout from '../../../../components/layout/PageLayout';
import { GetCustomers } from '../../../../services/customers';
import usePageLoader from '../../../../hooks/usePageLoader';
import { GetStatusLabels } from '../../../../functions/Labels';
import { DisplayDateTime } from '../../../../functions/Utilities';

export default function OptionsMain({currentUser}) {
    const [loader, showLoader, hideLoader] = usePageLoader();

    const merchantActions = [
        {
            title: 'Data Import',
            uri: 'imports/add'
        },
        {
            title: 'Update Variation Prices',
            uri: 'merchant-options/variation-prices'
        }
    ];

    const ActionItem = ({item}) => {
        return (
            <div className="list-action-item">
                <Link className="text-link-primary-x3" to={`/app/admin/${item.uri}`}>{item.title}</Link>
            </div>
        )
    }

    return (
        <PageLayout activeLink='merchant-options'>
            { loader }
            <h3 className="mb-4">Merchant Options</h3>
            <Card title="Select Merchant Action" className="bm-card">
                <List
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 3,
                        xl: 4,
                        xxl: 5,
                    }}
                    dataSource={merchantActions}
                    renderItem={item => (
                        <List.Item>
                            <ActionItem item={item} />
                        </List.Item>
                    )}
                />
            </Card>
        </PageLayout>
    )
}
